
const initialState = {
    caseScheduleError: {
      clientNameErr: {},
      datesErr:{}
    }
  };
  
  const caseScheduleErrorReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'CASESCHEDULEERROR':
        if (!action.payload) {
          return initialState;
        } else {
          return {
            ...state,
            caseScheduleError: action.payload
          };
        }
      default:
        return state;
    }
  };
  
  export default caseScheduleErrorReducer;