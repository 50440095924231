import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import is from 'is_js';
import { toast, ToastContainer } from 'react-toastify';
import 'assets/css/custom.css';
import 'assets/css/responsive.css';
import MainLayout from './MainLayout';
import HomeLayout from './HomeLayout';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import Home from 'components/Home';
import Error500 from 'components/errors/Error500';
import CardLogin from 'components/authentication/card/Login';
import CardPasswordReset from 'components/authentication/card/PasswordReset';
import Spinners from 'components/doc-components/Spinners';
import Dashboard from 'components/dashboards/default/dashboard';
import CardConfirmMail from 'components/authentication/card/ConfirmMail';
import Logout from 'components/authentication/card/Logout';
import Advocate from 'components/Advocate/Advocate';
import User from 'components/User/User';
import Client from 'components/Clients/Client';
import AdvocateClientCase from 'components/AdvocateClientCase/AdvocateClientCase';
import CaseNextDate from 'components/CaseNextDate/CaseNextDate';
import FilePlacement from 'components/FilePlacement/FilePlacement';
import SecurityRoleMaster from 'components/SecurityRoleMaster/SecurityRoleMaster';
import CountryMaster from 'components/CountryMaster/CountryMaster';
import CourtTypeMaster from 'components/CourtTypeMaster/CourtTypeMaster';
import SecurityMenuTreeDetails from 'components/SecurityMenuTree/SecurityMenuTreeDetails';
import CourtMaster from 'components/CourtMaster/CourtMaster';
import CaseSchedule from 'components/CaseSchedule/CaseSchedule';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        <Route element={<HomeLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/error" element={<Error500 />} />
        </Route>
        <Route path="/confirm-mail" element={<CardConfirmMail />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/forgot-password" element={<CardForgetPassword />} />
        <Route path="/login" element={<CardLogin />} />
        <Route path="/reset-password/:id" element={<CardPasswordReset />} />
        <Route path="/spinners" element={<Spinners />} />
        <Route element={<MainLayout />}>
        <Route path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/advocate" element={<Advocate />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/user" element={<User />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/client" element={<Client />} />
        </Route>
 
      <Route element={<MainLayout />}>
          <Route path="/case-registration" element={<AdvocateClientCase />} />
        </Route>

        <Route element={<MainLayout />}>
          <Route path="/case-next-date" element={<CaseNextDate/>} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/file-placement" element={<FilePlacement />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/security-role-master" element={<SecurityRoleMaster />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/country-master" element={<CountryMaster />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/court-type-master" element={<CourtTypeMaster />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/security-menu-tree" element={<SecurityMenuTreeDetails />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/court-master" element={<CourtMaster />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route path="/case-schedule" element={<CaseSchedule/>} />
        </Route>
        </Routes>
      <ToastContainer icon={false} position={toast.POSITION.TOP_RIGHT} />
    </>
  );
};

export default Layout;