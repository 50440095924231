import {
    CLIENT_TRANSACTION_DETAILS_LIST_REQUEST,
    CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS,
    CLIENT_TRANSACTION_DETAILS_LIST_FAILURE,
    CLIENT_TRANSACTION_DETAILS_REQUEST,
    CLIENT_TRANSACTION_DETAILS_SUCCESS,
    CLIENT_TRANSACTION_DETAILS_FAILURE,
    DOWNLOAD_TRANSACTION_DETAIL_PDF_FAILURE,
    DOWNLOAD_TRANSACTION_DETAIL_PDF_REQUEST,
    DOWNLOAD_TRANSACTION_DETAIL_PDF_SUCCESS,
} from "actions/ClientTransactionDetails/clientTransactionDetailsAction";

const initialState = {
    transactionDetails: [],
    transactionData: {},
};

export default function clientTransactionDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case 'TRANSACTIONLIST':
            if (!action.payload) {
                return initialState
            }
            else if (Array.isArray(action.payload)) {
                return {
                    ...state,
                    transactionDetails: action.payload
                }
            }
            else {
                return {
                    ...state,
                    transactionDetails: [...state.transactionDetails, action.payload]
                }
            }
            case 'TRANSACTIONDATA':
                if (!action.payload) {
                  return initialState;
                } else {
                  return {
                    ...state,
                    transactionData: action.payload
                  };
                }
        case CLIENT_TRANSACTION_DETAILS_LIST_REQUEST:
            return {
                ...state,
            };
        case CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS:
            return {
                ...state,
                transactionDetails: action.payload
            };
        case CLIENT_TRANSACTION_DETAILS_LIST_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case CLIENT_TRANSACTION_DETAILS_REQUEST:
            return {
                ...state,
            };

        case CLIENT_TRANSACTION_DETAILS_SUCCESS:
            return {
                ...state,
                transactionDetails: action.payload
            };
        case CLIENT_TRANSACTION_DETAILS_FAILURE:
            return {
                ...state,
                error: action.payload
            };
        case DOWNLOAD_TRANSACTION_DETAIL_PDF_REQUEST:
            return {
                ...state,
            };
        case DOWNLOAD_TRANSACTION_DETAIL_PDF_SUCCESS:
            return {
                ...state,
                invoicePurchaseDetail: action.payload,
            };
        case DOWNLOAD_TRANSACTION_DETAIL_PDF_FAILURE:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}