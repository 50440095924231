import { GET_CASE_SCHEDULE_LIST_REQUEST,
    GET_CASE_SCHEDULE_LIST_SUCCESS,
    GET_CASE_SCHEDULE_LIST_FAILURE
 } from "actions/CaseSchedule/caseScheduleAction";

 const initialState = {
    caseScheduleList: [],
    caseScheduleData: {},
  };

  export default function caseScheduleReducer(
    state = initialState,
    action
  ) {
    switch (action.type) {
      case GET_CASE_SCHEDULE_LIST_REQUEST:
        return {
          ...state
        };
      case GET_CASE_SCHEDULE_LIST_SUCCESS:
        return {
          ...state,
          caseScheduleList: action.payload
        };
      case GET_CASE_SCHEDULE_LIST_FAILURE:
        return {
          ...state,
          error: action.payload
        };
        case 'CASESCHEDULEDATA':
            if (!action.payload) {
                return initialState;
            } else {
                return {
                    ...state,
                    caseScheduleData: action.payload
                };
            }

      default:
        return state;
    }
  }