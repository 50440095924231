const initialState = {
  courtMasterDetailsError: {
    courtNameErr: {},
    courtTypeErr: {},
    countryNameErr: {},
    stateNameErr: {}
  }
};
const courtMasterErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COURTMASTERDETAILSERROR':
      if (!action.payload) {
        return initialState;
      } else {
        return {
          ...state,
          courtMasterDetailsError: action.payload
        };
      }
    default:
      return state;
  }
};

export default courtMasterErrorReducer;