import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import {
  advocateDetailsAction,
  advocateDetailsErrorAction,
  formChangedAction
} from 'actions';
import {
  advocateEmailMobileValidationAPI,
  advocateListAPI,
  advocateRegistrationAPI,
  advocateUpdateDetailsAPI
} from 'actions/AdvocateRegistration/advocateRegistrationAction';
import {
  addClientTransactionAPI,
  clientTransactionDetailsErrorAction,
  clientTransactionListAPI,
  sendInvoiceMailAPI,
  transactionDetailsAction,
  updateClientTransactionAPI
} from 'actions/ClientTransactionDetails/clientTransactionDetailsAction';
import {
  AddAdvocateDealingAPI,
  advocateDealingDetailAction,
  advocateDealingDetailsErrorAction,
  advocateDealingListAPI,
  deleteAdvocateDealingAPI,
  updateAdvocateDealingAPI
} from 'actions/AdvocateDealing/advocateDealingAction';
import axios from 'axios';

const tabArray = [
  'Advocate List',
  'Advocate Details',
  'Transaction Details',
  'Advocate Dealing'
];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'advocateName', Header: 'Advocate Name' },
  { accessor: 'mobileNo', Header: 'Mobile No' },
  { accessor: 'email', Header: 'Email ID' },
  { accessor: 'totalClient', Header: 'Total Client' },
  { accessor: 'totalCase', Header: 'Total No of Case' }
];

export const Advocate = () => {
  const dispatch = useDispatch();
  const [advocateList, setAdvocateList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [advocateDealingList, setAdvocateDealingList] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [isSendInvoiceMailModal, setIsSendInvoiceMailModal] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [currentCheck, setCurrentCheck] = useState(false);
  const [emailMobileValidationMessage, setEmailMobileValidationMessage] =
    useState();
  var UpdateEncryptedAdvocateId = localStorage.getItem('UpdateEncryptedAdvocateId');

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const advocateDetailsReducer = useSelector(
    state => state.rootReducer.advocateDetailsReducer
  );
  const advocateData = advocateDetailsReducer.advocateDetails;

  const advocateDealingReducer = useSelector(
    state => state.rootReducer.advocateDealingReducer
  );
  const advocateDealingDetails = advocateDealingReducer.advocateDealingDetails;

  const clientTransactionDetailReducer = useSelector(
    state => state.rootReducer.clientTransactionDetailsReducer
  );
  const transactionDetailData =
    clientTransactionDetailReducer.transactionDetails;
  const transactionData =
    clientTransactionDetailReducer.transactionData;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();

  useEffect(() => {
    getAdvocateList(1);
    localStorage.removeItem('DeleteAdvocateDealingId');
    localStorage.removeItem('UpdateEncryptedAdvocateId');
  }, []);

  useEffect(() => {
    if (!activeTabName) {
      $('[data-rr-ui-event-key*="Advocate Dealing"]').attr('disabled', true);
    }
  }, [activeTabName== "Advocate Dealing"]);

  useEffect(() => {
    if (
      transactionDetailData &&
      Array.isArray(transactionDetailData) &&
      transactionDetailData.length > 0 &&
      advocateData.encryptedAdvocateCode
    ) {
      const latestTransaction = transactionDetailData.reduce(
        (maxTransaction, transaction) => {
          const currentExpiryDate = new Date(transaction.expiryDate);
          const maxExpiryDate = new Date(maxTransaction.expiryDate);

          return currentExpiryDate > maxExpiryDate
            ? transaction
            : maxTransaction;
        },
        transactionDetailData[0]
      );

      const updatedAdvocateData = {
        ...advocateData,
        startDate: latestTransaction.startDate,
        expiryDate: latestTransaction.expiryDate
      };

      dispatch(advocateDetailsAction(updatedAdvocateData));
    }
  }, [transactionDetailData, advocateData.encryptedAdvocateCode, dispatch]);

  const clearAdvocateReducers = () => {
    dispatch(transactionDetailsAction(undefined));
    dispatch(advocateDetailsAction(undefined));
    dispatch(advocateDetailsErrorAction(undefined));
    dispatch(clientTransactionDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));
    dispatch(advocateDealingDetailAction(undefined));
  };

  $('[data-rr-ui-event-key*="Advocate List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="Advocate Dealing"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Transaction Details"]').attr(
          'disabled',
          true
        );
        $('[data-rr-ui-event-key*="Advocate Details"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Advocate List"]').attr('disabled', false);
        $('#AddAdvocateTransactionDetailsForm').get(0).reset();
        $('#addAdvocateDetailsForm').get(0).reset();
        localStorage.removeItem('EncryptedResponseAdvocateCode');
        localStorage.removeItem('DeleteAdvocateDealingId');
        localStorage.removeItem('UpdateEncryptedAdvocateId');
        $('#btnDiscard').attr('isDiscard', false);
        clearAdvocateReducers();
      }
    });

  $('[data-rr-ui-event-key*="Advocate Details"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Advocate Details');
      //  $('[data-rr-ui-event-key*="Advocate Dealing"]').attr('disabled', false);
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
      if (
        transactionDetailData.length <= 0 &&
        (localStorage.getItem('EncryptedResponseAdvocateCode') ||
          advocateData.encryptedAdvocateCode)
      ) {
        getTransactionDetailsList();
      }
    });

  $('[data-rr-ui-event-key*="Transaction Details"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Transaction Details');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
      if (
        transactionDetailData.length <= 0 &&
        (localStorage.getItem('EncryptedResponseAdvocateCode') ||
          advocateData.encryptedAdvocateCode)
      ) {
        getTransactionDetailsList();
      }
    });

  $('[data-rr-ui-event-key*="Advocate Dealing"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Advocate Dealing');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
      if (
        (!advocateDealingDetails || advocateDealingDetails.length <= 0) &&
        !localStorage.getItem('DeleteAdvocateDealingId') &&
        (localStorage.getItem('EncryptedResponseAdvocateCode') ||
          advocateData.encryptedAdvocateCode)
      ) {
        getAdvocateDealingList();
      }
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Details"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Advocate Details"]').trigger('click');
    $('[data-rr-ui-event-key*="Advocate Dealing"]').attr('disabled', false);
    $('#TransactionDetailsListCard').hide();
    $('#btnSave').attr('disabled', false);
    clearAdvocateReducers();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="Advocate List"]').trigger('click');
    }
  };

  const advocateValidation = () => {
    const advocateNameErr = {};
    const emailErr = {};
    const countryErr = {};
    const stateErr = {};
    const chamberNoErr = {};
    const mobileNoErr = {};
    const noOfUsersErr = {};
    const advocatePicFileErr = {};
    const transactionDetailErr = {};
    const advocateDealingErr = {};
    const practiceSinceErr = {};

    const maxFileSizeInBytes = 2 * 1024 * 1024;

    let isValid = true;
    let isAdvocateValid = true;
    let isTransactionValid = true;

    if (!advocateData.advocateName) {
      advocateNameErr.nameEmpty = 'Enter advocate name';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.chamberNo) {
      chamberNoErr.chamberNoEmpty = 'Enter chamber no';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.countryCode) {
      countryErr.empty = 'Select country';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (advocateData.practiceSince) {
      const currentYear = new Date().getFullYear();
      const practiceYear = new Date(advocateData.practiceSince).getFullYear();

      if (practiceYear > currentYear) {
        practiceSinceErr.empty = 'Since date should be less than or equal to the current year';
        isValid = false;
        isAdvocateValid = false;
        setFormError(true);
      }
    }

    if (!advocateData.stateCode) {
      stateErr.empty = 'Select state';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.mobileNo) {
      mobileNoErr.mobileNoErr = 'Enter mobile no';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    } else if (!/^(?!0)[0-9]{10}$/.test(advocateData.mobileNo)) {
      mobileNoErr.phoneInvalid =
        'Invalid mobile number, number should be of 10 digits should not start with 0';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (!advocateData.email) {
      emailErr.empty = 'Enter email address';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(advocateData.email)
    ) {
      emailErr.invalid = 'Please enter valid email address';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }

    if (advocateData.noOfUsers <= 0 || advocateData.noOfUsers === undefined) {
      noOfUsersErr.noOfUsersEmpty = 'Enter no. of users';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    } else if (!/^\d{1,3}$/.test(advocateData.noOfUsers)) {
      noOfUsersErr.noOfUsersInvalid =
        'Number of users can not be greater than 999';
      isValid = false;
      isAdvocateValid = false;
      setFormError(true);
    }
    if (
      advocateData.advocatePicFile != null &&
      advocateData.advocatePicFile != ''
    ) {
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];
      const fileExtension = (advocateData.advocatePicFile.name || '')
        .split('.')
        .pop()
        .toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        advocatePicFileErr.FileImgInvalid =
          'Invalid file extension. Please upload a file with extensions jpg, jpeg, png, or svg';
        isValid = false;
        isAdvocateValid = false;
        setFormError(true);
      }
      if (advocateData.advocatePicFile.size > maxFileSizeInBytes) {
        advocatePicFileErr.FileImgSize =
          'File size exceeds the maximum allowed 2 MB';
        isValid = false;
        isAdvocateValid = false;
        setFormError(true);
      }
    }

    if (!isAdvocateValid) {
      if (!$('[data-rr-ui-event-key*="Advocate Details"]').hasClass('active')) {
        $('[data-rr-ui-event-key*="Advocate Details"]').trigger('click');
      }
    }

    if (transactionDetailData.length < 1) {
      transactionDetailErr.transactionEmpty =
        'At least one transaction detail required';
      setTimeout(() => {
        toast.error(transactionDetailErr.transactionEmpty, {
          theme: 'colored'
        });
      }, 1000);
      isValid = false;
      isTransactionValid = false;

      if (
        isAdvocateValid &&
        !$('[data-rr-ui-event-key*="Transaction Details"]').hasClass('active')
      ) {
        $('[data-rr-ui-event-key*="Transaction Details"]').trigger('click');
      }
      setFormError(true);
    }

    if (advocateDealingDetails && advocateDealingDetails.length > 0) {
      advocateDealingDetails.forEach((row, index) => {
        if (!row.litigationCode || !row.subLitigationCode) {
          advocateDealingErr.invalidDetail = 'All the fields are required';
          isValid = false;
          setFormError(true);
        }
      });

      if (
        isAdvocateValid &&
        isTransactionValid &&
        !$('[data-rr-ui-event-key*="Advocate Dealing"]').hasClass('active')
      ) {
        toast.error(advocateDealingErr.invalidDetail, {
          theme: 'colored'
        });
        $('[data-rr-ui-event-key*="Advocate Dealing"]').trigger('click');
      }
    }

    if (!isValid) {
      var errorObject = {
        advocateNameErr,
        emailErr,
        countryErr,
        stateErr,
        chamberNoErr,
        mobileNoErr,
        noOfUsersErr,
        advocatePicFileErr,
        advocateDealingErr,
        practiceSinceErr
      };
      var errorTranObject = {
        transactionDetailErr
      };

      dispatch(advocateDetailsErrorAction(errorObject));
      dispatch(clientTransactionDetailsErrorAction(errorTranObject));
    }

    return isValid;
  };

  const addAdvocateDetails = async () => {
    if (advocateValidation()) {
      const formData = new FormData();
      formData.append('AdvocateName', advocateData.advocateName.toUpperCase());
      formData.append(
        'AdvocateAddress1',
        advocateData.advocateAddress1
          ? advocateData.advocateAddress1.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocateAddress2',
        advocateData.advocateAddress2
          ? advocateData.advocateAddress2.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocateAddress3',
        advocateData.advocateAddress3
          ? advocateData.advocateAddress3.toUpperCase()
          : ''
      );
      formData.append(
        'GSTNo',
        advocateData.gstNo
          ? advocateData.gstNo
          : ''
      );
      formData.append('CountryCode', advocateData.countryCode);
      formData.append('StateCode', advocateData.stateCode);
      formData.append('EmailId', advocateData.email.toUpperCase());
      formData.append('NoOfUsers', advocateData.noOfUsers);
      formData.append('MobileNo', advocateData.mobileNo);
      formData.append(
        'PracticingSince',
        advocateData.practiceSince
          ? advocateData.practiceSince.toUpperCase()
          : ''
      );
      formData.append('ChamberNo', advocateData.chamberNo);
      formData.append(
        'landlineNo',
        advocateData.landlineNo ? advocateData.landlineNo : ''
      );
      formData.append(
        'NeedSEO',
        advocateData.needSEO ? advocateData.needSEO.toUpperCase() : ''
      );
      formData.append(
        'ClientProfile',
        advocateData.clientProfile
          ? advocateData.clientProfile.toUpperCase()
          : ''
      );
      formData.append(
        'ContactPerson',
        advocateData.contactPerson
          ? advocateData.contactPerson.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocatePictureFile',
        advocateData.advocatePicFile ? advocateData.advocatePicFile : null
      );
      formData.append(
        'AddUser',
        localStorage.getItem('LoginUserName').toUpperCase()
      );

      transactionDetailData.forEach((Detail, index) => {
        formData.append(
          `AddClientTransactionDetails[${index}].RoleId`,
          parseInt(Detail.roleId)
        );
        formData.append(
          `AddClientTransactionDetails[${index}].PaymentType`,
          Detail.paymentType
        );
        formData.append(
          `AddClientTransactionDetails[${index}].PaymentNo`,
          Detail.paymentNo ? Detail.paymentNo : ''
        );
        formData.append(
          `AddClientTransactionDetails[${index}].BillingAmount`,
          Detail.billingAmount
        );
        formData.append(
          `AddClientTransactionDetails[${index}].Startdate`,
          Detail.startDate
        );
        formData.append(
          `AddClientTransactionDetails[${index}].ExpiryDate`,
          Detail.expiryDate
        );
        formData.append(
          `AddClientTransactionDetails[${index}].TotalAmount`,
          Detail.totalAmount
        );
        formData.append(
          `AddClientTransactionDetails[${index}].AddUser`,
          Detail.addUser.toUpperCase()
        );
        formData.append(
          `AddClientTransactionDetails[${index}].GSTPercent`,
          Detail.gstPercent ? Detail.gstPercent : ''
        );
        formData.append(
          `AddClientTransactionDetails[${index}].InvoiceNo`,
          Detail.invoiceNo ? Detail.invoiceNo : ''
        );
        formData.append(
          `AddClientTransactionDetails[${index}].InvoiceDate`,
          Detail.invoiceDate
        );
        formData.append(
          `AddClientTransactionDetails[${index}].qty`,
          Detail.qty
        );
        formData.append(
          `AddClientTransactionDetails[${index}].hsnCode`,
          Detail.hsnCode
        );
      });

      advocateDealingDetails.forEach((Detail, index) => {
        formData.append(
          `AddAdvocateDealing[${index}].LitigationCode`,
          Detail.litigationCode
        );
        formData.append(
          `AddAdvocateDealing[${index}].SublitigationCode`,
          Detail.subLitigationCode
        );
      });

      setIsLoading(true);
      await dispatch(
        advocateRegistrationAPI(
          formData,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            updateCallback(true, response.data.data.encryptedAdvocateCode);
            setModalShow(false);
            localStorage.setItem(
              'EncryptedResponseAdvocateCode',
              response.data.data.encryptedAdvocateCode
            );
            dispatch(advocateDetailsAction({
              ...advocateData,
              encryptedAdvocateCode: response.data.data.encryptedAdvocateCode,
              startDate: response.data.data.startDate,
              expiryDate: response.data.data.expiryDate
            }))
          },
          err => {
            setIsLoading(false);
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  };

  const updateCallback = (isAddAdvocate = false, encryptedAdvocateCode) => {
    
    setModalShow(false);
    dispatch(advocateDetailsErrorAction(undefined));
    dispatch(formChangedAction(undefined));
    clearAdvocateLocalStorages();

    if (!isAddAdvocate) {
      toast.success('Advocate details updated successfully!', {
        theme: 'colored'
      });
    } else {
      dispatch(
        advocateDetailsAction({
          ...advocateData,
          encryptedAdvocateCode: encryptedAdvocateCode
        })
      );
    }

    setTimeout(() => {
      getTransactionDetailsList();
      getAdvocateDealingList();
    }, 50);

    $('#btnSave').attr('disabled', true);
    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');

    getAdvocateList(1);
  };

  const updateAdvocateDetails = async (IsSendMail) => {
    if (advocateValidation()) {
      const formData = new FormData();
      formData.append(
        'EncryptedAdvocateCode',
        advocateData.encryptedAdvocateCode
      );
      formData.append('AdvocateName', advocateData.advocateName.toUpperCase());
      formData.append(
        'AdvocateAddress1',
        advocateData.advocateAddress1
          ? advocateData.advocateAddress1.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocateAddress2',
        advocateData.advocateAddress2
          ? advocateData.advocateAddress2.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocateAddress3',
        advocateData.advocateAddress3
          ? advocateData.advocateAddress3.toUpperCase()
          : ''
      );
      formData.append(
        'GSTNo',
        advocateData.gstNo
          ? advocateData.gstNo
          : ''
      );
      formData.append('CountryCode', advocateData.countryCode);
      formData.append('StateCode', advocateData.stateCode);
      formData.append('EmailId', advocateData.email.toUpperCase());
      formData.append('MobileNo', advocateData.mobileNo);
      formData.append('NoOfUsers', advocateData.noOfUsers);
      formData.append(
        'PracticingSince',
        advocateData.practiceSince
          ? advocateData.practiceSince.toUpperCase()
          : ''
      );
      formData.append('ChamberNo', advocateData.chamberNo);
      formData.append(
        'landlineNo',
        advocateData.landlineNo ? advocateData.landlineNo : ''
      );
      formData.append(
        'NeedSEO',
        advocateData.needSEO != null ? advocateData.needSEO.toUpperCase() : ''
      );
      formData.append(
        'ClientProfile',
        advocateData.clientProfile
          ? advocateData.clientProfile.toUpperCase()
          : ''
      );
      formData.append(
        'ContactPerson',
        advocateData.contactPerson
          ? advocateData.contactPerson.toUpperCase()
          : ''
      );
      formData.append(
        'AdvocatePictureFile',
        advocateData.advocatePicFile ? advocateData.advocatePicFile : null
      );
      formData.append(
        'modifyUser',
        localStorage.getItem('LoginUserName').toUpperCase()
      );
      var hasError = false;
      setIsLoading(true);
      if (formChangedData.advocateUpdate) {
        await dispatch(
          advocateUpdateDetailsAPI(
            formData,
            res => {
              setIsLoading(false);
              setModalShow(false);
            },
            err => {
              setIsLoading(false);
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
              hasError = true;
            },
            headers
          )
        );
      }
      var advocateDealingDetailIndex = 1;
      var transactionDetailIndex = 1;
      if (!hasError && (formChangedData.transactionDetailAdd || formChangedData.transactionDetailUpdate)) {

        var UpdateEncryptedAdvocateIds = localStorage.getItem('UpdateEncryptedAdvocateId');
        var UpdateEncryptedAdvocateList = UpdateEncryptedAdvocateIds
          ? UpdateEncryptedAdvocateIds.split(',')
          : null;

        for (let i = 0; i < transactionDetailData.length; i++) {
          const transactionDetails = transactionDetailData[i];
          const encryptedAdvocateId = transactionDetails.encryptedAdvocateId;
          if (
            formChangedData.transactionDetailUpdate &&
            encryptedAdvocateId &&
            UpdateEncryptedAdvocateList.includes(encryptedAdvocateId.toString())
          ) {

            const data = {
              encryptedAdvocateCode: localStorage.getItem(
                'EncryptedResponseAdvocateCode'
              ),
              encryptedAdvocateId:
                transactionDetails.encryptedAdvocateId,
              paymentNo: transactionDetails.paymentNo,
              roleId: transactionDetails.roleId,

              roleName: transactionDetails.roleName,
              paymentType: transactionDetails.paymentType,
              billingAmount: transactionDetails.billingAmount,
              totalAmount: transactionDetails.totalAmount,
              startDate: transactionDetails.startDate,
              expiryDate: transactionDetails.expiryDate,
              gstPercent: transactionDetails.gstPercent,
              modifyUser: localStorage.getItem('LoginUserName'),
              invoiceNo: transactionDetails.invoiceNo,
              invoiceDate: transactionDetails.invoiceDate,
              hsnCode: transactionDetails.hsnCode,
              qty: transactionDetails.qty
            }
            await dispatch(
              updateClientTransactionAPI(
                data,
                res => {
                  setIsLoading(false);
                  setModalShow(false);
                },
                err => {
                  setIsLoading(false);
                  toast.error(err?.data.message, {
                    theme: 'colored',
                    autoClose: 10000
                  });
                  hasError = true;
                },
                headers
              )
            );
            if (hasError) break;
          } else if (
            formChangedData.transactionDetailAdd &&
            !transactionDetails.encryptedAdvocateId
          ) {

            setIsLoading(true);
            await dispatch(
              addClientTransactionAPI(
                transactionDetails,
                response => {
                  setIsLoading(false);
                  setModalShow(false);
                },
                err => {
                  setIsLoading(false);
                  toast.error(err?.data.message, {
                    theme: 'colored',
                    autoClose: 10000
                  });
                },
                headers
              )
            );
            if (hasError) break;
          }
          transactionDetailIndex++;
        }
      }

      var deleteAdvocateDealingId = localStorage.getItem(
        'DeleteAdvocateDealingId'
      );
      if (
        !hasError &&
        formChangedData.advocateDealingDelete &&
        deleteAdvocateDealingId
      ) {
        var deleteAdvocateDealingDeleteList = deleteAdvocateDealingId
          ? deleteAdvocateDealingId.split(',')
          : null;
        if (deleteAdvocateDealingDeleteList) {
          var deleteAdvocateDealingDetailIndex = 1;
          for (let i = 0; i < deleteAdvocateDealingDeleteList.length; i++) {
            const advocateDealingId = deleteAdvocateDealingDeleteList[i];
            const data = { encryptedAdvocateDealingId: advocateDealingId };
            await dispatch(
              deleteAdvocateDealingAPI(
                data,
                response => {
                  getAdvocateDealingList();
                  setIsLoading(false);
                },
                err => {
                  setIsLoading(false);
                  toast.error(err?.data.message, {
                    theme: 'colored',
                    autoClose: 10000
                  });
                },
                headers
              )
            );
          }
          deleteAdvocateDealingDetailIndex++;
        }
      }
      for (let i = 0; i < advocateDealingDetails.length; i++) {
        const dealingDetails = advocateDealingDetails[i];
        if (
          formChangedData.advocateDealingUpdate &&
          dealingDetails.encryptedAdvocateDealingId
        ) {
          const dealingData = {
            encryptedAdvocateDealingId:
              dealingDetails.encryptedAdvocateDealingId,
            litigationCode: dealingDetails.litigationCode,
            subLitigationCode: dealingDetails.subLitigationCode
          };
          await dispatch(
            updateAdvocateDealingAPI(
              dealingData,
              res => {
                setIsLoading(false);
                setModalShow(false);
                // getAdvocateDealingList();
              },
              err => {
                setIsLoading(false);
                toast.error(err?.data.message, {
                  theme: 'colored',
                  autoClose: 10000
                });
                hasError = true;
              },
              headers
            )
          );
        } else if (
          formChangedData.advocateDealingAdd &&
          !dealingDetails.encryptedAdvocateDealingId
        ) {
          setIsLoading(true);
          await dispatch(
            AddAdvocateDealingAPI(
              dealingDetails,
              response => {
                setIsLoading(false);
                setModalShow(false);
                // getAdvocateDealingList();
              },
              err => {
                setIsLoading(false);
                toast.error(err?.data.message, {
                  theme: 'colored',
                  autoClose: 10000
                });
              },
              headers
            )
          );
        }
        advocateDealingDetailIndex++;
      }
      if (IsSendMail) {
        if (!hasError && (formChangedData?.transactionDetailAdd || formChangedData?.transactionDetailUpdate)) {
          dispatch(
            sendInvoiceMailAPI(
              localStorage.getItem(
                'EncryptedResponseAdvocateCode')
              ,
              (response) => {
                setIsLoading(false)
              },
              (err) => {
                console.log(err?.message)
                setIsLoading(false)
              }
            )
          );
        }
      }
      if (!hasError) {
        updateCallback();
      }
    }
  };

  const clearAdvocateLocalStorages = () => {
    localStorage.removeItem('DeleteAdvocateDealingId');
    localStorage.removeItem('UpdateEncryptedAdvocateId');
  };

  const getAdvocateList = async (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: ''
    };
    setIsLoading(true);
    await dispatch(
      advocateListAPI(
        data,
        response => {
          setIsLoading(false);
          setAdvocateList(response.data.data.advocateList);
        },
        err => {
          setAdvocateList([]);
          setIsLoading(false);
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
        },
        headers
      )
    );
  };

  const getAdvocateDealingList = async () => {
    const data = {
      EncryptedAdvocateCode: localStorage.getItem(
        'EncryptedResponseAdvocateCode'
      )
        ? localStorage.getItem('EncryptedResponseAdvocateCode')
        : advocateData.encryptedAdvocateCode
    };
    setIsLoading(true);
    await dispatch(
      advocateDealingListAPI(
        data,
        response => {
          setIsLoading(false);
          setAdvocateDealingList(response);
        },
        err => {
          setIsLoading(false);
          setAdvocateDealingList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true')
      window.location.href = '/dashboard';
    else $('[data-rr-ui-event-key*="List"]').trigger('click');

    setModalShow(false);
  };

  const getTransactionDetailsList = async () => {
    const requestParams = {
      EncryptedAdvocateCode: localStorage.getItem(
        'EncryptedResponseAdvocateCode'
      )
        ? localStorage.getItem('EncryptedResponseAdvocateCode')
        : advocateData.encryptedAdvocateCode
    };
    setIsLoading(true);
    await dispatch(
      clientTransactionListAPI(
        requestParams,
        res => {
          setIsLoading(false);
          let transactionDetailsData = [];
          transactionDetailsData =
            res.data.data.length > 0 ? res.data.data : [];
          dispatch(transactionDetailsAction(transactionDetailsData));
          if (res.data && res.data.data.length > 0) {
            $('#TransactionDetailsTable').show();
            $('#TransactionDetailsListCard').show();
          } else {
            $('#TransactionDetailsTable').hide();
            $('#TransactionDetailsListCard').hide();
            
          }
        },
        err => {
          setIsLoading(false);
          dispatch(transactionDetailsAction(undefined));
          $('#TransactionDetailsTable').hide();
        },
        headers
      )
    );
  };

  const checkEmailMobileValidation = async () => {
    if (advocateValidation()) {
      const requestData = {
        email: advocateData.email,
        phoneNumber: '',
        encryptedAdvocateCode: advocateData.encryptedAdvocateCode
          ? advocateData.encryptedAdvocateCode
          : '',
        isUpdate: advocateData.encryptedAdvocateCode ? true : false
      };

      setIsLoading(true);
      await dispatch(
        advocateEmailMobileValidationAPI(
          requestData,
          response => {
            console.log('api call success for email');
            setIsLoading(false);
            handleResponse(response, 'email');
          },
          err => {
            setIsLoading(false);
            console.log(err?.data.message);
          },
          headers
        )
      );
    }
  };

  const handleResponse = async (response, type) => {
    const message = response?.data?.message || '';
    if (response?.data?.status === 200) {
      if (type === 'email') {
        setConfirmationModal(true);
        setCurrentCheck('email');
      } else if (type === 'mobile') {
        setConfirmationModal(true);
        setCurrentCheck('mobile');
      }
      setEmailMobileValidationMessage(message);
    } else {
      if (type === 'email') {
        await validateMobile();
      } else {
        if (advocateData.encryptedAdvocateCode) {
          if (formChangedData.transactionDetailAdd || formChangedData.transactionDetailUpdate) {
            setIsSendInvoiceMailModal(true);
          } else {
            await updateAdvocateDetails(false);
          }
        } else {
          addAdvocateDetails();
        }
      }
    }
  };

  const validateMobile = async () => {
    const requestData = {
      email: '', // Clear email to check only mobile
      phoneNumber: advocateData.mobileNo,
      encryptedAdvocateCode: advocateData.encryptedAdvocateCode
        ? advocateData.encryptedAdvocateCode
        : '',
      isUpdate: advocateData.encryptedAdvocateCode ? true : false
    };

    setIsLoading(true);
    dispatch(
      advocateEmailMobileValidationAPI(
        requestData,
        response => {
          console.log('api call success for mobile');
          setIsLoading(false);
          handleResponse(response, 'mobile');
        },
        err => {
          setIsLoading(false);
          console.log(err?.data?.message);
        },
        headers
      )
    );
  };

  const handleConfirm = async () => {
    setConfirmationModal(false);

    if (currentCheck === 'email') {
      setCurrentCheck('');
      await validateMobile();
    } else if (currentCheck === 'mobile') {
      setCurrentCheck('');

      // Both checks passed, proceed with save
      if (advocateData.encryptedAdvocateCode) {
        updateAdvocateDetails();
      } else {
        addAdvocateDetails();
      }
    }
  };

  const handleSendInvoiceEmailConfirm = async (isConfirmed) => {
    setIsSendInvoiceMailModal(false);
    const sendMail = isConfirmed;
    await updateAdvocateDetails(sendMail);
  };

  const handleNoClick = async () => {
    setConfirmationModal(false);
    setSendMail(false)
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => checkEmailMobileValidation()}
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {confirmationModal && (
        <Modal
          show={confirmationModal}
          onHide={() => setConfirmationModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{emailMobileValidationMessage}</h4>
            <h5>
              Do you want to{' '}
              {advocateData.encryptedAdvocateCode ? 'update' : 'register'} with
              this advocate as well?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => handleConfirm()}>
              Yes
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => handleNoClick()}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {isSendInvoiceMailModal && (
        <Modal
          show={isSendInvoiceMailModal}
          onHide={() => setIsSendInvoiceMailModal(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Do you want to send email with updated transaction details to Advocate?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success"
              onClick={() => handleSendInvoiceEmailConfirm(true)} >
              Yes
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => handleSendInvoiceEmailConfirm(false)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage
        listData={advocateList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="Advocate"
        // saveDetails={advocateData.encryptedAdvocateCode ? updateAdvocateDetails : addAdvocateDetails}
        saveDetails={checkEmailMobileValidation}
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
      />
    </>
  );
};

export default Advocate;
