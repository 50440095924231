import request from 'components/Utils/Request';
const { REACT_APP_API_URL } = process.env;
export const GET_CASE_SCHEDULE_LIST_REQUEST = 'GET_CASE_SCHEDULE_LIST_REQUEST';
export const GET_CASE_SCHEDULE_LIST_SUCCESS = 'GET_CASE_SCHEDULE_LIST_SUCCESS';
export const GET_CASE_SCHEDULE_LIST_FAILURE = 'GET_CASE_SCHEDULE_LIST_FAILURE';

export const getCaseSchedulelistRequest = () => ({
    type: GET_CASE_SCHEDULE_LIST_REQUEST
  });
  export const getCaseSchedulelistSuccess = payload => ({
    type: GET_CASE_SCHEDULE_LIST_SUCCESS,
    payload
  });
  export const getCaseSchedulelistFailure = payload => ({
    type: GET_CASE_SCHEDULE_LIST_FAILURE,
    payload
  });

  export const caseScheduleAction = payload => {
    return {
      type: 'CASESCHEDULEDATA',
      payload
    };
  };

  export const caseScheduleDetailsErrorAction = payload => {
    return {
      type: 'CASESCHEDULEDETAILSERROR',
      payload
    };
  };

  export const getCaseScheduleListAPI = (payload, successData, errorMSg, headers = {}) => {
    return dispatch => {
      dispatch(getCaseSchedulelistRequest());
      return request(
        'POST',
        `${REACT_APP_API_URL + '/get-client-case-schedule-list'}`,
        payload,
        "",
        headers
      )
        .then(res => {
          if (res?.data.status === 200) {
            successData(res.data);
            dispatch(getCaseSchedulelistSuccess(res.data));
          } else {
            errorMSg(res);
          }
        })
        .catch(err => {
          errorMSg(err?.res);
          dispatch(getCaseSchedulelistFailure('Something went wrong'));
          throw err;
        });
    };
  };