import React, { useEffect, useState } from 'react';
import TabPage from 'components/common/TabPage';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { formChangedAction } from 'actions';
import { addCourtTypeMasterAPI, courtTypeDataDetailAction, courtTypeDetailErrorAction, getCourtTypeMasterListAPI, updateCourtTypeMasterAPI } from 'actions/CourtTypeMaster/courtTypeMasterAction';

const tabArray = ['Court Type List', 'Add Court Type'];

const listColumnArray = [
  { accessor: 'sl', Header: 'S. No' },
  { accessor: 'courtTypeCode', Header: 'Court Type Code' },
  { accessor: 'courtTypeName', Header: 'Court Type Name' },
];

export const CourtTypeMaster = () => {
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(15);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [courtTypeList, setCourtTypeList] = useState([]);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getCourtTypeList = (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
    };
    setIsLoading(true);
    dispatch(
      getCourtTypeMasterListAPI(
        data,
        response => {
          setIsLoading(false);
          setCourtTypeList(response.data.data);
        },
        err => {
          setIsLoading(false)
          setCourtTypeList([]);
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  useEffect(() => {
    getCourtTypeList(1, perPage)
    $('[data-rr-ui-event-key*="Add Court Type"]').attr('disabled', true);
  }, []);

  const courtTypeMasterReducer = useSelector((state) => state.rootReducer.courtTypeMasterReducer)
  var courtTypeData = courtTypeMasterReducer.courtTypeDetail;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  
  var formChangedData = formChangedReducer.formChanged;

  let isFormChanged = Object.values(formChangedData).some(
    value => value === true
  );

  const [formHasError, setFormError] = useState(false);
  const [activeTabName, setActiveTabName] = useState();

  const clearCourtTypeReducer = () => {
    dispatch(courtTypeDataDetailAction(undefined));
    dispatch(courtTypeDetailErrorAction(undefined));
    dispatch(formChangedAction(undefined));
  };

  $('[data-rr-ui-event-key*="Court Type List"]')
    .off('click')
    .on('click', function () {
      let isDiscard = $('#btnDiscard').attr('isDiscard');
      if (isDiscard != 'true' && isFormChanged) {
        setModalShow(true);
        localStorage.removeItem('EncryptedResponseCountryCode');
        setTimeout(function () {
          $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
        }, 50);
      } else {
        $('#btnNew').show();
        $('#btnSave').hide();
        $('#btnCancel').hide();
        $('[data-rr-ui-event-key*="Add Court Type"]').attr('disabled', true);
        $('[data-rr-ui-event-key*="Court Type List"]').attr('disabled', false);
        $('#addCourtTypeDetailsForm').get(0).reset();
        getCourtTypeList(1, perPage)
        localStorage.removeItem('EncryptedResponseCourtTypeCode');
        $('#btnDiscard').attr('isDiscard', false);
         clearCourtTypeReducer();
      }
    });

  $('[data-rr-ui-event-key*="Add Court Type"]')
    .off('click')
    .on('click', function () {
      setActiveTabName('Add Court Type');
      $('#btnNew').hide();
      $('#btnSave').show();
      $('#btnCancel').show();
    });

  const newDetails = () => {
    $('[data-rr-ui-event-key*="Add Court Type"]').attr('disabled', false);
    $('[data-rr-ui-event-key="Add Court Type"]').trigger('click');
    $('#btnSave').attr('disabled', false);
     clearCourtTypeReducer();
  };

  const cancelClick = () => {
    $('#btnExit').attr('isExit', 'false');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      $('[data-rr-ui-event-key*="Court Type List"]').trigger('click');
    }
  };

  const exitModule = () => {
    $('#btnExit').attr('isExit', 'true');
    if (isFormChanged) {
      setModalShow(true);
    } else {
      window.location.href = '/dashboard';
    }
  };

  const discardChanges = () => {
    $('#btnDiscard').attr('isDiscard', 'true');
    if ($('#btnExit').attr('isExit') == 'true'){
      window.location.href = '/dashboard';
    }
    else {$('[data-rr-ui-event-key*="List"]').trigger('click');
    setModalShow(false);
    }
  };

  const courtTypeValidation = () => {
    const courtTypeNameErr = {};
    let isValid = true;

    if (!courtTypeData.courtTypeName) {
      courtTypeNameErr.nameEmpty = 'Enter court type name';
      isValid = false;
      setFormError(true);
    }

    if (!isValid) {
      var errorObject = {
        courtTypeNameErr,
      };
      dispatch(courtTypeDetailErrorAction(errorObject));
    }
    return isValid;
  };

  const addCourtTypeDetails = () => {
    if (courtTypeValidation()) {
      const requestData = {
        courtTypeName: courtTypeData.courtTypeName,
        createdBy: localStorage.getItem('LoginUserName')
      };

      const keys = [
        'courtTypeName',
        'createdBy'
      ];
      for (const key of Object.keys(requestData).filter(key => keys.includes(key))) {
        requestData[key] = requestData[key] ? requestData[key].toUpperCase() : '';
      }
  
      setIsLoading(true);
      dispatch(
        addCourtTypeMasterAPI(
          requestData,
          response => {
            setIsLoading(false);
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            dispatch(
              courtTypeDataDetailAction({
                 ...courtTypeData,
                 encryptedCourtTypeCode: response.data.data.encryptedCourtTypeCode,
                  courtTypeCode: response.data.data.courtTypeCode,
              })
            );
            dispatch(courtTypeDetailErrorAction(undefined));
            updateCallback(true, response.data.data.encryptedCourtTypeId);
            setModalShow(false);
            localStorage.setItem(
              'EncryptedResponseCourtTypeCode',
              response.data.data.encryptedCourtTypeCode
            );
          },
          err => {
            setIsLoading(false)
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  };

  const updateCallback = (isAddState = false, ) => {
    setModalShow(false);
    dispatch(formChangedAction(undefined));
    if (!isAddState) {
      toast.success('Court type detail updated successfully!', {
        theme: 'colored'
      });
    }

    $('#btnSave').attr('disabled', true);
    getCourtTypeList(1, perPage);

    $('[data-rr-ui-event-key*="' + activeTabName + '"]').trigger('click');
  };

  const updateCourtTypeDetails = async () => {
    if (courtTypeValidation()) {
      const updateRequestData = {
        encryptedCourtTypeCode : localStorage.getItem("EncryptedResponseCourtTypeCode"),
        courtTypeName: courtTypeData.courtTypeName,
        modifiedBy: localStorage.getItem('LoginUserName')
      };

      const keys = [
        'courtTypeName',
        'modifiedBy'
      ];
      for (const key of Object.keys(updateRequestData).filter(key => keys.includes(key))) {
        updateRequestData[key] = updateRequestData[key] ? updateRequestData[key].toUpperCase() : '';
      }
      var hasError = false;
      if (formChangedData.courtTypeUpdate) {
        setIsLoading(true);
        dispatch(
          updateCourtTypeMasterAPI(
            updateRequestData,
            (response) => {
              setIsLoading(false)
              setModalShow(false);
              updateCallback(false);
            },
            (err) => {
              hasError = true;
              setIsLoading(false)
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
            },
            headers
          )
        );
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner
          className="position-absolute start-50 loader-color"
          animation="border"
        />
      ) : null}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Do you want to save changes?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={
                !courtTypeData.encryptedCountryCode
                  ? addCourtTypeDetails
                  : updateCourtTypeDetails
              }
            >
              Save
            </Button>
            <Button
              variant="danger"
              id="btnDiscard"
              onClick={() => discardChanges()}
            >
              Discard
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <TabPage
        listData={courtTypeList}
        listColumnArray={listColumnArray}
        tabArray={tabArray}
        module="CourtTypeMaster"
        saveDetails={
          courtTypeData.encryptedCourtTypeCode
            ? updateCourtTypeDetails
            : addCourtTypeDetails
        }
        newDetails={newDetails}
        cancelClick={cancelClick}
        exitModule={exitModule}
      />
    </>
  );
};

export default CourtTypeMaster;