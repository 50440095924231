import React, { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from 'actions';
import FalconComponentCard from 'components/common/FalconComponentCard.js';
import { countryDetailAction } from 'actions/CountryMaster/countryMasterAction.js';

const CountryMasterDetail = () => {
	const dispatch = useDispatch();

	const resetCountryData = () => {
		dispatch(
			countryDetailAction({
				"countryName": '',
				"countryCode": ''
			})
		);
	}

	const countryMasterReducer = useSelector((state) => state.rootReducer.countryMasterReducer)
	var countryMasterData = countryMasterReducer.countryDetail;

	const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
	var formChangedData = formChangedReducer.formChanged;

	const countryMasterErrorReducer = useSelector((state) => state.rootReducer.countryMasterErrorReducer)
	const countryDetailsErr = countryMasterErrorReducer.countryDetailsError;

	if (
		!countryMasterReducer.countryDetail ||
		Object.keys(countryMasterReducer.countryDetail).length <= 0
	) {
		resetCountryData();
	}

	const handleCountryFieldChange = e => {
		if (e.target.name == 'countryName') {
			dispatch(
				countryDetailAction({
					...countryMasterData,
					countryName: e.target.value,
				})
			);
		}

		if (countryMasterData.encryptedCountryCode) {
			dispatch(
				formChangedAction({
					...formChangedData,
					countryUpdate: true
				})
			);
		} else {
			dispatch(
				formChangedAction({
					...formChangedData,
					countryAdd: true
				})
			);
		}
	};
	
	return (
		<>
			<FalconComponentCard className="no-pb mb-1">
				<FalconComponentCard.Body language="jsx">
					<Form className="details-form"
						id="addCountryDetailsForm">
						<Row>
							<Col md="4">
								<Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
									<Form.Label column sm="4">
										Country Code
									</Form.Label>
									<Col sm="8">
										<Form.Control id="txtCountryCode" name="countryCode" maxLength={5} placeholder="Country Code"
											value={countryMasterData.countryCode}
											disabled />
									</Col>
								</Form.Group>
							</Col>
							<Col md="4">
								<Form.Group as={Row} className="mb-1" controlId="formPlaintextPassword">
									<Form.Label column sm="4">
										Country Name<span className="text-danger">*</span>
									</Form.Label>
									<Col sm="8">
										<Form.Control id="txtCountryName" maxLength={50} name="countryName" placeholder="Country Name"
											value={countryMasterData.countryName}
											onChange={(e) => handleCountryFieldChange(e)}
										/>
										{Object.keys(countryDetailsErr.countryNameErr).map(key => {
											return (
												<span className="error-message">
													{countryDetailsErr.countryNameErr[key]}
												</span>
											);
										})}
									</Col>

								</Form.Group>
							</Col>
						</Row>
					</Form>
				</FalconComponentCard.Body>
			</FalconComponentCard>
		</>
	)
}

export default CountryMasterDetail