import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Moment from 'moment';
import { Button, Modal, Table } from 'react-bootstrap';
import {
  downloadTransactionDetailPdfAPI,
  transactionDataAction,
  transactionDetailsAction
} from 'actions/ClientTransactionDetails/clientTransactionDetailsAction';
import { useDispatch } from 'react-redux';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';

const TransactionDetailList = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [paramsData, setParamsData] = useState({});

  const clientTransactionDetailReducer = useSelector(
    state => state.rootReducer.clientTransactionDetailsReducer
  );

  const transactionDetail = clientTransactionDetailReducer.transactionData;

  const clientTransactionDetailsErrorReducer = useSelector(
    state => state.rootReducer.clientTransactionDetailsErrorReducer
  );
  const transactionError =
    clientTransactionDetailsErrorReducer.clientTransactionDetailsError;

  useEffect(() => {
    const count = $('#TransactionDetailsTable tr').length;
    if (count > 1) {
      $('#TransactionDetailsTable').show();
    } else {
      $('#TransactionDetailsTable').hide();
    }

    if (transactionError.transactionDetailErr.Count > 0) {
      $('#TransactionDetailsListCard').show();
    } else {
      $('#TransactionDetailsListCard').hide();
    }
  }, []);

  const ModalPreview = Id => {
    setModalShow(true);
    setParamsData(Id);
  };

  const deleteRow = index => {
    const updatedTransactionDetails = [
      ...clientTransactionDetailReducer.transactionDetails.slice(0, index),
      ...clientTransactionDetailReducer.transactionDetails.slice(index + 1)
    ];
    dispatch(transactionDetailsAction(updatedTransactionDetails));
    toast.success('Transaction detail deleted successfully!', {
      theme: 'colored',
      autoClose: 10000
    });
    setModalShow(false);
  };

  const handleFieldChange = (data) => {

    if (!data.encryptedAdvocateId) return false;
    var encryptedAdvocateId = localStorage.getItem("UpdateEncryptedAdvocateId")

    let encryptedAdvocateIdArray = encryptedAdvocateId ? encryptedAdvocateId.split(",") : [];

    if (!encryptedAdvocateIdArray.includes(data.encryptedAdvocateId)) {
      encryptedAdvocateIdArray.push(data.encryptedAdvocateId);
      localStorage.setItem("UpdateEncryptedAdvocateId", encryptedAdvocateIdArray.join(","));
    }

    dispatch(
      transactionDataAction({
        ...transactionDetail,
        encryptedAdvocateId: data.encryptedAdvocateId,
        advocateId: data.advocateId,
        paymentNo: data.paymentNo,
        roleId: data.roleId,
        roleName: data.roleName,
        paymentType: data.paymentType,
        billingAmount: data.billingAmount,
        totalAmount: data.totalAmount,
        startDate: data.startDate,
        expiryDate: data.expiryDate,
        gstPercent: data.gstPercent,
        invoiceNo: data.invoiceNo,
        qty: data.qty,
        invoiceDate: data.invoiceDate,
        hsnCode: data.hsnCode,
      })
    );
  };

  const downloadPdf = (advocateId) => {
    dispatch(downloadTransactionDetailPdfAPI(advocateId));
  };

  return (
    <>
      {transactionError.transactionDetailErr &&
        transactionError.transactionDetailErr.transactionEmpty && (
          <div className="p-1">
            <span className="error-message">
              {transactionError.transactionDetailErr.transactionEmpty}
            </span>
          </div>
        )}

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Are you sure, you want to delete this transaction detail?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={() => setModalShow(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => deleteRow(paramsData)}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {clientTransactionDetailReducer &&
        clientTransactionDetailReducer.transactionDetails &&
        clientTransactionDetailReducer.transactionDetails.length > 0 && (
          <Table
            striped
            bordered
            responsive
            id="TransactionDetailsTable"
            className="no-pb text-nowrap tab-page-table"
          >
            <thead className="custom-bg-200">
              <tr>
                <th>S. No</th>
                <th>Role Name</th>
                <th>Invoice No.</th>
                <th>Start Date</th>
                <th>Expiry Date</th>
                <th>Payment Type</th>
                <th>Payment No.</th>
                <th>Qty</th>
                <th>Billing Amount</th>
                <th>GST %</th>
                <th>GST Amount</th>
                <th>Total Amount</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {clientTransactionDetailReducer.transactionDetails.map(
                (data, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{data.roleName}</td>
                    <td>{data.invoiceNo}</td>
                    <td>{Moment(data.startDate).format('DD/MM/YYYY')}</td>
                    <td>{Moment(data.expiryDate).format('DD/MM/YYYY')}</td>
                    <td>{data.paymentType}</td>
                    <td>{data.paymentNo}</td>
                    <td>{data.qty}</td>
                    <td>{data.billingAmount}</td>
                    <td>{data.gstPercent}%</td>
                    <td>{data.gstAmount}</td>
                    <td>{data.totalAmount}</td>
                    <td>
                      <span onClick={() => handleFieldChange(data)}>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="edit"
                          class="svg-inline--fa fa-edit fa-w-15 fa-2x"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                        >
                          <path
                            fill="currentColor"
                            d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                          ></path>
                        </svg>
                      </span>
                      <span
                        title="Download Invoice"
                        onClick={() => downloadPdf(data.encryptedAdvocateId)}
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="edit"
                          class="svg-inline--fa fa-edit fa-w-15 fa-2x"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M19 8C20.66 8 22 9.34 22 11V17H18V21H6V17H2V11C2 9.34 3.34 8 5 8H6V3H18V8H19M8 5V8H16V5H8M16 19V15H8V19H16M18 15H20V11C20 10.45 19.55 10 19 10H5C4.45 10 4 10.45 4 11V15H6V13H18V15M19 11.5C19 12.05 18.55 12.5 18 12.5C17.45 12.5 17 12.05 17 11.5C17 10.95 17.45 10.5 18 10.5C18.55 10.5 19 10.95 19 11.5Z" />
                        </svg>
                      </span>
                    </td>
                    <td>
                      {!data.encryptedAdvocateId ? (
                        <IconButton
                          variant="falcon-default"
                          size="sm"
                          icon="trash"
                          iconClassName="me-1"
                          onClick={() => ModalPreview(index)}
                          className="me-1 mb-2 mb-sm-0 hide-on-print"
                        ></IconButton>
                      ) : (
                        ''
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        )}
    </>
  );
};

export default TransactionDetailList;
