import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { formChangedAction } from 'actions';
import { actionDataDetailAction, actionDetailErrorAction, addActionDetailsAPI, getActionDetailsListAPI, updateActionDetailAPI } from 'actions/ActionDetails/actionDetailsAction';
import Moment from "moment";

export const ActionModal = (prop) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const selectedClientCode = localStorage.getItem('ClientCode')
  const selectedCaseNo = localStorage.getItem('CaseNo')
  const selectedClientName = localStorage.getItem('ClientName')

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');

  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const resetActionDetailData = () => {
    dispatch(actionDataDetailAction({
      'date': '',
      'reminderStatus': '',
      'reminderDays': '',
      'actionItem': '',
    })
    );
  };

  const caseNextDateReducer = useSelector(
    state => state.rootReducer.caseNextDateReducer
  );
  var caseNextDateData = caseNextDateReducer.caseNextDateData

  const actionDetailsReducer = useSelector(
    state => state.rootReducer.actionDetailsReducer
  );
  var actionDetailsData = actionDetailsReducer.actionDetailsData

  if (!actionDetailsReducer.actionDetailsData ||
    Object.keys(actionDetailsReducer.actionDetailsData).length <= 0) {
    resetActionDetailData();
  }

  const actionDetailsErrorReducer = useSelector(
    state => state.rootReducer.actionDetailsErrorReducer
  );
  const actionDetailsError = actionDetailsErrorReducer.actionDetailsError;

  const formChangedReducer = useSelector((state) => state.rootReducer.formChangedReducer)
  var formChangedData = formChangedReducer.formChanged;

  useEffect(() => {
    if (actionDetailsReducer.actionDetailList.length > 0) {
      getActionDetailList(selectedClientCode, selectedCaseNo)
    }
  }, [])

  const getActionDetailList = async (ClientCode, caseNo) => {
    const data = {
      ClientCode: ClientCode ? ClientCode : selectedClientCode,
      CaseNo: caseNo ? caseNo : selectedCaseNo,
    };
    await dispatch(
      getActionDetailsListAPI(
        data,
        res => {
          let listData = [];
          listData = res.data.data.length > 0 ? res.data.data.actionDetailsList : [];
          dispatch(actionDataDetailAction(listData))
        },
        err => {
          dispatch(actionDataDetailAction(undefined))
          console.log(err?.data.message);
        },
        headers
      )
    );
  };

  const actionDataValidation = () => {
    const nextActionDateErr = {};
    const reminderBeforeDaysErr = {};
    const actionItemErr = {};

    let isValid = true;
    if (!actionDetailsData.date) {
      nextActionDateErr.empty = 'Enter next date';
      isValid = false;
    }
    else if (actionDetailsData.date <= caseNextDateData.caseRegisterDate) {
      nextActionDateErr.endDateInvalid = "Next action date cannot be same or less than register Date";
      isValid = false;
    } else if (actionDetailsData.oldDate) {
      if (actionDetailsData.date <= actionDetailsData.oldDate) {
        nextActionDateErr.endDateInvalid = "Next action date cannot be same or less than previous action date";
        isValid = false;
      }
    }

    if (!actionDetailsData.reminderDays) {
      reminderBeforeDaysErr.caseNoEmpty = 'Enter reminder days';
      isValid = false;
    }
    if (!actionDetailsData.actionItem) {
      actionItemErr.caseNoEmpty = 'Enter action item';
      isValid = false;
    }

    if (!isValid) {
      var errorObject = {
        nextActionDateErr,
        reminderBeforeDaysErr,
        actionItemErr
      };
      dispatch(actionDetailErrorAction(errorObject));
    }

    return isValid;
  };

  const addActionDetails = async () => {
    if (actionDataValidation()) {
      const data = {
        EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
        ClientCode: caseNextDateData.clientCode ? caseNextDateData.clientCode : selectedClientCode,
        RegistrationDate: caseNextDateData.caseRegisterDate,
        NextDate: actionDetailsData.date,
        CaseNo: caseNextDateData.clientCaseNo ? caseNextDateData.clientCaseNo : selectedCaseNo,
        ReminderDays: actionDetailsData.reminderDays,
        ReminderStatus: actionDetailsData.reminderStatus,
        ActionItem: actionDetailsData.actionItem ? actionDetailsData.actionItem : "",
        CreatedBy: localStorage.getItem('LoginUserName'),
        AddUser: localStorage.getItem('LoginUserName')
      }
      const keys = [
        'ActionItem'
      ];
      for (const key of Object.keys(data).filter(key => keys.includes(key))) {
        data[key] = data[key] ? data[key].toUpperCase() : '';
      }
      await dispatch(
        addActionDetailsAPI(
          data,
          response => {
            toast.success(` ${response.data.message}`, {
              theme: 'colored',
              autoClose: 10000
            });
            dispatch(formChangedAction(undefined));
            resetActionDetailData()
            getActionDetailList(selectedClientCode, selectedCaseNo)
            dispatch(actionDetailErrorAction(undefined));
          },
          err => {
            toast.error(err?.data.message, {
              theme: 'colored',
              autoClose: 10000
            });
          },
          headers
        )
      );
    }
  }

  const updateActionDetails = async () => {
    if (actionDataValidation()) {
      const data = {
        EncryptedActionDetailId: actionDetailsData.encryptedActionDetailsId,
        EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
        ClientCode: caseNextDateData.clientCode ? caseNextDateData.clientCode : selectedClientCode,
        RegistrationDate: caseNextDateData.caseRegisterDate,
        NextDate: actionDetailsData.date,
        CaseNo: caseNextDateData.clientCaseNo ? caseNextDateData.clientCaseNo : selectedCaseNo,
        ReminderDays: actionDetailsData.reminderDays,
        ReminderStatus: actionDetailsData.reminderStatus,
        ActionItem: actionDetailsData.actionItem ? actionDetailsData.actionItem : "",
        ModifiedBy: localStorage.getItem('LoginUserName')
      }
      const keys = [
        'ActionItem'
      ];
      for (const key of Object.keys(data).filter(key => keys.includes(key))) {
        data[key] = data[key] ? data[key].toUpperCase() : '';
      }
      if (formChangedData.actionDetailsDataUpdate) {
        setIsLoading(true);
        await dispatch(
          updateActionDetailAPI(
            data,
            response => {
              setIsLoading(false);
              toast.success(` ${response.data.message}`, {
                theme: 'colored',
                autoClose: 10000
              });
              dispatch(formChangedAction(undefined));
              resetActionDetailData()
              getActionDetailList(selectedClientCode, selectedCaseNo)
              dispatch(actionDetailErrorAction(undefined));
            },
            err => {
              toast.error(err?.data.message, {
                theme: 'colored',
                autoClose: 10000
              });
            },
            headers
          )
        );
      }
    }
  };

  const handleFieldChange = (e, data) => {
    if (data) {
      dispatch(
        actionDataDetailAction({

          ...data,
          actionItem: data.actionItem,
          reminderDays: data.reminderDays,
          date: data.date,
          reminderStatus: data.reminderStatus,
          oldDate: data.date,
        })
      );
    } else {
      dispatch(
        actionDataDetailAction({
          ...actionDetailsData,
          [e.target.name]: e.target.value
        })
      );
    }

    if (actionDetailsData.encryptedActionDetailsId) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          actionDetailsDataAdd: true
        })
      );
    } else {
      dispatch(
        formChangedAction({
          ...formChangedData,
          actionDetailsDataUpdate: true
        })
      );
    }
  };


  const handleCloseModal = () => {
    prop.setActionDetailModal(false);
    resetActionDetailData()
    dispatch(actionDetailErrorAction(undefined))
  };

  const handleSort = (column) => {
    let direction = 'asc';
    if (sortConfig.key === column && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: column, direction });
  };

  const sortClass = (column) => {
    if (sortConfig.key !== column) return '';
    return sortConfig.direction === 'asc' ? 'sort-asc' : 'sort-desc';
  };

  const sortedData = React.useMemo(() => {
    if (!actionDetailsReducer.actionDetailList) return [];
  
    let sortedList = [...actionDetailsReducer.actionDetailList];
  
    if (sortConfig.key) {
      sortedList.sort((a, b) => {
        const aValue = a[sortConfig.key] ? a[sortConfig.key].toString().trim() : '';
        const bValue = b[sortConfig.key] ? b[sortConfig.key].toString().trim() : '';
  
        const isANumber = !isNaN(Number(aValue));
        const isBNumber = !isNaN(Number(bValue));
  
        if (isANumber && isBNumber) {
          return sortConfig.direction === 'asc'
            ? Number(aValue) - Number(bValue)
            : Number(bValue) - Number(aValue);
        }
  
        const lowerA = aValue.toLowerCase();
        const lowerB = bValue.toLowerCase();
        if (lowerA < lowerB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (lowerA > lowerB) return sortConfig.direction === 'asc' ? 1 : -1;
  
        return 0;
      });
    }
  
    return sortedList;
  }, [actionDetailsReducer.actionDetailList, sortConfig]);

  return (
    <>
      <Modal
        show={prop.actionDetailModal}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter text-center">Action Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="details-form" id="FarmerDetails" >
            <Row className="ms-1">
              <Col md='6' className="me-3 ms-3 ">
                <Form.Group as={Row} className="mb-1 " controlId="formHorizontalEmail">
                  <Form.Label column sm={4} className='label-colon'>
                  Client Name<span>:</span>
                  </Form.Label>
                  <Col sm={8} className='action-text-input'>
                    <div className='actionText '> {caseNextDateData.clientName ? caseNextDateData.clientName : selectedClientName}</div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formHorizontalEmail">
                  <Form.Label column sm={4} className='label-colon'>
                  Case No.<span>:</span>
                  </Form.Label>
                  <Col sm={8} className='action-text-input'>
                    <div className='actionText'>{caseNextDateData.clientCaseNo}</div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formHorizontalEmail">
                  <Form.Label column sm={4} className='label-colon'>
                  Action Status<span>:</span>
                  </Form.Label>
                  <Col sm={8} className='action-text-input'>
                    <Form.Select
                      id="txtReminderStatus"
                      name="reminderStatus"
                      onChange={handleFieldChange}
                      value={actionDetailsData.reminderStatus}
                      disabled={actionDetailsData.encryptedActionDetailsId ? false : true} >
                      <option value="Open">Open</option>
                      <option value="Close">Close</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formHorizontalEmail">
                  <Form.Label column sm={4} className='label-colon'>
                    Action Description<span>:</span>
                  </Form.Label>
                  <Col sm={8} className='action-text-input'>
                  <Form.Control id="txtActionItem"
                      name="actionItem"
                      as="textarea"
                       className="custom-textarea"
                      rows={3}
                      maxLength={200}
                      onChange={handleFieldChange}
                      value={actionDetailsData.actionItem}
                      placeholder="Describe the Description(200 characters remaining)" />
                    {Object.keys(actionDetailsError.actionItemErr).map(key => {
                      return (
                        <span className="error-message">
                          {actionDetailsError.actionItemErr[key]}
                        </span>
                      );
                    })}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formHorizontalEmail">
                  <Form.Label column sm={4} className='label-colon'>
                    Action Date<span>:</span>
                  </Form.Label>
                  <Col sm={8} className='action-text-input'>
                    <Form.Control
                      id="txtActionDate"
                      name="date"
                      type='Date'
                      className="form-control"
                      onChange={handleFieldChange}
                      value={actionDetailsData.date}
                    />
                    {Object.keys(actionDetailsError.nextActionDateErr).map(key => {
                      return (
                        <span className="error-message">
                          {actionDetailsError.nextActionDateErr[key]}
                        </span>
                      );
                    })}
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formHorizontalEmail">
                  <Form.Label column sm={4} className='label-colon'>
                    Reminder Before Days<span>:</span>
                  </Form.Label>
                  <Col sm={8} className='action-text-input'>
                    <Form.Control
                      id="txtReminderBeforeDays"
                      name="reminderDays"
                      placeholder="When to be reminded"
                      maxLength={2}
                      onKeyPress={(e) => {
                        const regex = /[0-9]|\./;
                        const key = String.fromCharCode(e.charCode);
                        if (!regex.test(key)) {
                          e.preventDefault();
                        }
                      }}
                      value={actionDetailsData.reminderDays}
                      onChange={handleFieldChange}
                    />
                    {Object.keys(actionDetailsError.reminderBeforeDaysErr).map(key => {
                      return (
                        <span className="error-message">
                          {actionDetailsError.reminderBeforeDaysErr[key]}
                        </span>
                      );
                    })}
                  </Col>
                </Form.Group>
              </Col>
              <Col md='5' className="me-3 ms-3">
              <Form.Group as={Row} className="mb-1" controlId="formHorizontalEmail">
                  <Form.Label column sm={4} className='label-colon'>
                    Case Title<span>:</span>
                  </Form.Label>
                  <Col sm={8} className='action-text-input'>
                    <div className='actionText'> {caseNextDateData.caseTitle}</div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-1" controlId="formHorizontalEmail">
                  <Form.Label column sm={4} className='label-colon'>
                    Case Nature<span>:</span>
                  </Form.Label>
                  <Col sm={8} className='action-text-input'>
                    <div className='actionText'> {caseNextDateData.caseNature}</div>
                  </Col>
                </Form.Group>

              </Col>
   
            </Row>
            <hr className='seprator' /> 
            <hr  className='seprator' /> 
            <Row>
            <FalconComponentCard className="farmer-card-row1 noBoxShadow">
                <FalconComponentCard.Header title="Previous Action List" light={false}  className='action-list-card-header'/>
                <FalconComponentCard.Body language="jsx">
                  <Row>
                    {actionDetailsReducer.actionDetailList && actionDetailsReducer.actionDetailList.length > 0 ? (
                    <Table striped bordered responsive id="TableList" className="no-pb text-nowrap tab-page-table custom-table">
                      <thead className="custom-bg-200">
                      <tr>
                        <th>S.No</th>
                        <th onClick={() => handleSort('date')} className={`sort ${sortClass('date')}`}>Action Date</th>
                        <th onClick={() => handleSort('reminderDays')} className={`sort ${sortClass('reminderDays')}`}>Days</th>
                        <th onClick={() => handleSort('reminderStatus')} className={`actionTd sort ${sortClass('reminderStatus')}`}>Status</th>
                        <th onClick={() => handleSort('actionItem')} className={`sort ${sortClass('actionItem')}`}>Action Description</th>
                        <th>Edit</th>
                      </tr>
                      </thead>
                      <tbody>
                        {sortedData.map((data, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{Moment(data.date).format('DD-MM-YYYY')}</td>
                            <td>{data.reminderDays}</td>
                            <td>{data.reminderStatus}</td>
                            <td className='action-description'>{data.actionItem}</td>
                            <td>
                              <span onClick={(e) => handleFieldChange(e, data)}>
                                <svg
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="edit"
                                  className="svg-inline--fa fa-edit fa-w-15 fa-2x"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 576 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                                  ></path>
                                </svg>
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    ) : (
                      <h5>No record found</h5>
                    )}
                  </Row>
                </FalconComponentCard.Body>
              </FalconComponentCard>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" id='btnAction'
            onClick={actionDetailsData.encryptedActionDetailsId ? updateActionDetails : addActionDetails}
          >Save</Button>
          <Button variant="danger"
            onClick={handleCloseModal}
          >Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionModal;