import axios from "axios";
import request from "components/Utils/Request";
export const CLIENT_TRANSACTION_DETAILS_LIST_REQUEST = "CLIENT_TRANSACTION_DETAILS_LIST_REQUEST";
export const CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS = "CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS";
export const CLIENT_TRANSACTION_DETAILS_LIST_FAILURE = "CLIENT_TRANSACTION_DETAILS_LIST_FAILURE";
export const CLIENT_TRANSACTION_DETAILS_REQUEST = "CLIENT_TRANSACTION_DETAILS_REQUEST";
export const CLIENT_TRANSACTION_DETAILS_SUCCESS = "CLIENT_TRANSACTION_DETAILS_SUCCESS";
export const CLIENT_TRANSACTION_DETAILS_FAILURE = "CLIENT_TRANSACTION_DETAILS_FAILURE";
export const UPDATE_CLIENT_TRANSACTION_DETAILS_REQUEST = "UPDATE_CLIENT_TRANSACTION_DETAILS_REQUEST";
export const UPDATE_CLIENT_TRANSACTION_DETAILS_SUCCESS = "UPDATE_CLIENT_TRANSACTION_DETAILS_SUCCESS";
export const UPDATE_CLIENT_TRANSACTION_DETAILS_FAILURE = "UPDATE_CLIENT_TRANSACTION_DETAILS_FAILURE";
export const DOWNLOAD_TRANSACTION_DETAIL_PDF_REQUEST = "DOWNLOAD_TRANSACTION_DETAIL_PDF_REQUEST";
export const DOWNLOAD_TRANSACTION_DETAIL_PDF_SUCCESS = "DOWNLOAD_TRANSACTION_DETAIL_PDF_SUCCESS";
export const DOWNLOAD_TRANSACTION_DETAIL_PDF_FAILURE = "DOWNLOAD_TRANSACTION_DETAIL_PDF_FAILURE";
export const SEND_INVOICE_MAIL_REQUEST = "SEND_INVOICE_MAIL_REQUEST";
export const SEND_INVOICE_MAIL_SUCCESS = "SEND_INVOICE_MAIL_SUCCESS";
export const SEND_INVOICE_MAIL_FAILURE = "SEND_INVOICE_MAIL_FAILURE";
const { REACT_APP_API_URL } = process.env;

export const clientTransactionDetailsListRequest = () => ({
	type: CLIENT_TRANSACTION_DETAILS_LIST_REQUEST
});
export const clientTransactionDetailsListSuccess = (payload) => ({
	type: CLIENT_TRANSACTION_DETAILS_LIST_SUCCESS,
	payload
});
export const clientTransactionDetailsListFailure = (payload) => ({
	type: CLIENT_TRANSACTION_DETAILS_LIST_FAILURE,
	payload
})

export const addClientTransactionDetailsRequest = () => ({
	type: CLIENT_TRANSACTION_DETAILS_REQUEST
});
export const AddClientTransactionDetailsSuccess = (payload) => ({
	type: CLIENT_TRANSACTION_DETAILS_SUCCESS,
	payload
});
export const addClientTransactionDetailsFailure = (payload) => ({
	type: CLIENT_TRANSACTION_DETAILS_FAILURE,
	payload
})

export const updateClientTransactionDetailsRequest = () => ({
	type: UPDATE_CLIENT_TRANSACTION_DETAILS_REQUEST
});
export const updateClientTransactionDetailsSuccess = (payload) => ({
	type: UPDATE_CLIENT_TRANSACTION_DETAILS_SUCCESS,
	payload
});
export const updateClientTransactionDetailsFailure = (payload) => ({
	type: UPDATE_CLIENT_TRANSACTION_DETAILS_FAILURE,
	payload
})

export const downloadTransactionDetailPdfRequest = () => ({
    type: DOWNLOAD_TRANSACTION_DETAIL_PDF_REQUEST,
});
export const downloadTransactionDetailPdfSuccess = (payload) => ({
    type: DOWNLOAD_TRANSACTION_DETAIL_PDF_SUCCESS,
    payload,
});
export const downloadTransactionDetailPdfFailure = (payload) => ({
    type: DOWNLOAD_TRANSACTION_DETAIL_PDF_FAILURE,
    payload,
});

export const sendInvoiceMailRequest = () => ({
	type: SEND_INVOICE_MAIL_REQUEST
});
export const sendInvoiceMailSuccess = (payload) => ({
	type: SEND_INVOICE_MAIL_SUCCESS,
	payload
});
export const sendInvoiceMailFailure = (payload) => ({
	type: SEND_INVOICE_MAIL_FAILURE,
	payload
})


export const transactionDetailChangedAction = (payload) => {
    return {
        type: 'TRANSACTIONDETAILCHANGED',
        payload
    };
};

export const transactionDetailsAction = (payload) => {
    return {
        type: 'TRANSACTIONLIST',
        payload
    };
};
export const sendEmailInvoiceAction = (payload) => {
    return {
        type: 'SENDEINVOICEMAIL',
        payload
    };
};


export const transactionDataAction = (payload) => {
    return {
        type: 'TRANSACTIONDATA',
        payload
    };
};

export const clientTransactionDetailsErrorAction = (payload) => {
    return {
      type: 'CLIENTTRANSACTIONDETAILSERROR',
      payload
    };
  };


export const clientTransactionListAPI = (payload, successData, errorMSg, headers = {}) => {
	return (dispatch) => {
		dispatch(clientTransactionDetailsListRequest());
		return request("POST", `${REACT_APP_API_URL + "/client-transaction-detail-list"}`, payload, "", headers)
			.then((res) => {
				if (res?.data.status === 200) {
                    var data = res?.data.data
					successData(res)
					dispatch(clientTransactionDetailsListSuccess(data));
				} else {
					errorMSg(res)
				}
			}).catch((err) => {
				errorMSg(err?.res);
				dispatch(clientTransactionDetailsListFailure("Something went wrong"));
				throw err;
			})
	}
}

export const addClientTransactionAPI = (payload, successData, errorMsg, headers = {}) => {
	return (dispatch) => {
		dispatch(addClientTransactionDetailsRequest());
		return request("POST", `${REACT_APP_API_URL + "/add-client-transaction-details"}`, payload, "", headers)
			.then((response) => {
				if (response?.data?.status === 200) {
					successData(response);
					dispatch(AddClientTransactionDetailsSuccess(response));
				} else {
					errorMsg(response);
				}

			}).catch((error) => {
				errorMsg(error?.response);
				dispatch(addClientTransactionDetailsFailure("Something Went Wrong"));
				throw error;
			});
	};
};

export const updateClientTransactionAPI = (payload, successData, errorMsg, headers = {}) => {
	return (dispatch) => {
		dispatch(updateClientTransactionDetailsRequest());
		return request("POST", `${REACT_APP_API_URL + "/update-client-transaction-details"}`, payload, "", headers)
			.then((response) => {
				if (response?.data?.status === 200) {
					successData(response);
					dispatch(updateClientTransactionDetailsSuccess(response));
				} else {
					errorMsg(response);
				}

			}).catch((error) => {
				errorMsg(error?.response);
				dispatch(updateClientTransactionDetailsFailure("Something Went Wrong"));
				throw error;
			});
	};
}

export const downloadTransactionDetailPdfAPI = advocateId => {
  return dispatch => {
    dispatch(downloadTransactionDetailPdfRequest());
    return axios
      .get(
        REACT_APP_API_URL + '/generate-client-transaction-pdf/' + advocateId,
        { responseType: 'blob' }
      )
      .then(response => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const downloadUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `Invoice.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          dispatch(downloadTransactionDetailPdfSuccess());
        } else {
          dispatch(downloadTransactionDetailPdfFailure(response.data.message));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(
          downloadTransactionDetailPdfFailure(
            'Something went wrong, please try again.'
          )
        );
      });
  };
};

export const sendInvoiceMailAPI = (payload, successData, errorMsg) => {
	return dispatch => {
	  dispatch(sendInvoiceMailRequest());
	  const url = `${REACT_APP_API_URL}/send-invoice-mail/${payload}`;
	  return request('GET', url)
		.then(response => {
		  if (response?.data?.status === 200) {
			successData(response);
			dispatch(sendInvoiceMailSuccess(response));
		  } else {
			errorMsg(response);
		  }
		})
		.catch(error => {
		  errorMsg(error?.response);
		  dispatch(sendInvoiceMailFailure('Something went wrong'));
		  throw error;
		});
	};
  };