import React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { formChangedAction } from 'actions';
import { toast } from 'react-toastify';
import Moment from 'moment';
import EnlargableTextbox from 'components/common/EnlargableTextbox';
import { securityRoleMasterListAPI } from 'actions/SecurityRoleMaster/securityRoleMasterAction';
import { transactionDataAction, transactionDetailsAction } from 'actions/ClientTransactionDetails/clientTransactionDetailsAction';

export const TransactionDetails = () => {
  const dispatch = useDispatch();
  const [amountPayable, setAmountPayable] = useState();
  const [gstAmount, setGSTAmount] = useState();
  const [roleList, setRoleList] = useState([]);
  const [formHasError, setFormError] = useState(false);
  const [roleNameErr, setRoleNameErr] = useState({});
  const [startDateErr, setStartDateErr] = useState({});
  const [expiryDateErr, setExpiryDateErr] = useState({});
  const [amountErr, setAmountErr] = useState({});
  const [paymentTypeErr, setPaymentTypeErr] = useState({});
  const [paymentNoErr, setPaymentNoErr] = useState({});
  const [invoiceNoErr, setInvoiceNoErr] = useState({});

  const [invoiceDateError, setInvoiceDateError] = useState({});
  const [qtyError, setQtyError] = useState({});
  const [hsnCodeError, setHsnCodeError] = useState({});

  const resetTransactionData = () => {
    dispatch(transactionDataAction({
      'encryptedRoleId': '',
      'roleName': '',
      'roleId': '',
      'advocateCode': '',
      'paymentType': '',
      'paymentNo': '',
      'startDate': '',
      'expiryDate': '',
      'amount': '',
      'totalAmount': '',
      'invoiceNo': '',
      "invoiceDate": Moment().format('YYYY-MM-DD'),
      'qty': 1,
      'hsnCode': '',
      'billingAmount': '',
      'gstPercent': '',
    })
    );
    setRoleList([])
  };

  const clientTransactionDetailReducer = useSelector(
    state => state.rootReducer.clientTransactionDetailsReducer
  );
  const transactionDetailList =
    clientTransactionDetailReducer.transactionDetails;
  const transactionDetail =
    clientTransactionDetailReducer.transactionData;
  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );

  if (!clientTransactionDetailReducer.transactionData ||
    clientTransactionDetailReducer.transactionData.length <= 0) {
    resetTransactionData();
  }
  var formChangedData = formChangedReducer.formChanged;
  useEffect(() => {
    $('[data-rr-ui-event-key*="Advocate Dealing"]').attr('disabled', false);
    getRole();
    $('.payment-type-details').hide();
    if (transactionDetail.paymentType == "cash") {
      $('#txtPaymentNo').attr('disabled', true);
    }
  }, []);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getRole = async () => {
    await dispatch(
      securityRoleMasterListAPI(
        res => {
          let roleData = [];
          if (res.data && res.data.data.roleMasterList.length > 0)
            res.data.data.roleMasterList.forEach(role => {
              roleData.push({
                key: role.roleName,
                value: role.roleId
              });
            });
          setRoleList(roleData);
        },
        err => {
          toast.error(err?.data.message, {
            theme: 'colored',
            autoClose: 10000
          });
          setRoleList([]);
        },
        headers
      )
    );
  };

  const validateTransactionDetails = () => {
    const roleNameErr = {};
    const startDateErr = {};
    const expiryDateErr = {};
    const amountErr = {};
    const paymentTypeErr = {};
    const paymentNoErr = {};
    const invoiceNoErr = {};
    const invoiceDateErr = {};
    const qtyErr = {};
    const hsnCodeErr = {};

    let isValid = true;

    if (!transactionDetail.roleId) {
      roleNameErr.roleNameEmpty = 'Select role name';
      isValid = false;
      setFormError(true);
    }

    if (!transactionDetail.startDate) {
      startDateErr.startDateEmpty = 'Select start date';
      isValid = false;
      setFormError(true);
    }

    if (!transactionDetail.invoiceDate || transactionDetail.invoiceDate === "") {
      invoiceDateError.invoiceDateEmpty = 'Select invoice date';
      isValid = false;
      setFormError(true);
    }
    if (!transactionDetail.qty && transactionDetail.qty == "0") {
      qtyErr.qtyEmpty = 'Enter qty';
      isValid = false;
      setFormError(true);
    }
    if (!transactionDetail.hsnCode) {
      hsnCodeErr.hsnCodeEmpty = 'Enter hsn code';
      isValid = false;
      setFormError(true);
    }


    if (!transactionDetail.expiryDate) {
      expiryDateErr.expiryDateEmpty = 'Select expiry date';
      isValid = false;
      setFormError(true);
    } else if (transactionDetail.expiryDate <= transactionDetail.startDate) {
      expiryDateErr.expiryDateInvalid =
        'Expiry date cannot be same or less than Start Date';
      isValid = false;
      setFormError(true);
    }

    if (transactionDetail.billingAmount <= 0) {
      amountErr.amountEmpty = 'Billing amount should be greater than zero';
      isValid = false;
      setFormError(true);
    }

    if (!transactionDetail.paymentType) {
      paymentTypeErr.paymentTypeEmpty = 'Select payment type';
      isValid = false;
      setFormError(true);
    }

    if (transactionDetail.paymentType != 'Cash') {
      if (!transactionDetail.paymentNo) {
        paymentNoErr.paymentNoEmpty = 'Enter payment no.';
        isValid = false;
        setFormError(true);
      }
    }
    if (!transactionDetail.invoiceNo) {
      invoiceNoErr.invoiceNoEmpty = 'Enter invoice no';
      isValid = false;
      setFormError(true);
    }

    if (!isValid) {
      setRoleNameErr(roleNameErr);
      setStartDateErr(startDateErr);
      setExpiryDateErr(expiryDateErr);
      setAmountErr(amountErr);
      setPaymentTypeErr(paymentTypeErr);
      setPaymentNoErr(paymentNoErr);
      setInvoiceNoErr(invoiceNoErr);
      setHsnCodeError(hsnCodeErr);
      setInvoiceDateError(invoiceDateErr);
      setQtyError(qtyErr);
    }

    return isValid;
  };

  const clearStates = () => {
    setFormError(false);
    setRoleNameErr({});
    setStartDateErr({});
    setExpiryDateErr({});
    setAmountErr({});
    setPaymentTypeErr({});
    setPaymentNoErr({});
    setInvoiceNoErr({});
    setHsnCodeError({});
    setInvoiceDateError({});
    setQtyError({});
  };

  const submitTransactionDetails = e => {    
    e.preventDefault();
    transactionDetail.invoiceDate =  transactionDetail.invoiceDate? transactionDetail.invoiceDate : Moment().format('YYYY-MM-DD')
    const form = e.currentTarget;
    if (validateTransactionDetails()) {
      const transactionData = {
        encryptedAdvocateCode: localStorage.getItem(
          'EncryptedResponseAdvocateCode'
        ),
        encryptedAdvocateId: transactionDetail.encryptedAdvocateId,
        roleId: transactionDetail.roleId,
        roleName: $('#setRoleName').find('option:selected').text(),
        startDate: transactionDetail.startDate,
        expiryDate: transactionDetail.expiryDate,
        paymentType: transactionDetail.paymentType,
        paymentNo: transactionDetail.paymentNo ? transactionDetail.paymentNo : '',
        gstPercent: !isNaN(parseFloat(transactionDetail.gstPercent))
          ? parseFloat(transactionDetail.gstPercent)
          : 0,
        billingAmount: parseFloat(transactionDetail.billingAmount),
        totalAmount: amountPayable ? amountPayable : transactionDetail.totalAmount,
        addUser: localStorage.getItem('LoginUserName'),
        qty: transactionDetail.qty ? transactionDetail.qty : 1,
        invoiceDate: transactionDetail.invoiceDate ? transactionDetail.invoiceDate : Moment().format('YYYY-MM-DD'),
        hsnCode: transactionDetail.hsnCode,
        invoiceNo: transactionDetail.invoiceNo,
        gstAmount: gstAmount ? gstAmount : transactionDetail.gstAmount
      };
      dispatch(
        transactionDataAction({
          ...transactionData,
          advocateId: transactionDetail.advocateId
        })
      );

      var loopBreaked = false;
      transactionDetailList.forEach(detail => {
        
        if (!loopBreaked) {
          if (detail.advocateId === transactionDetail.advocateId ) {
            return; // Ignore the current record
          }
          if (detail.roleId == transactionDetail.roleId) {
            if ((Moment(detail.startDate).format("YYYY-MM-DD") <= transactionDetail.startDate && Moment(detail.endDate).format("YYYY-MM-DD") >= transactionDetail.startDate) ||
              (Moment(detail.startDate).format("YYYY-MM-DD") <= transactionDetail.endDate && Moment(detail.endDate).format("YYYY-MM-DD") >= transactionDetail.endDate) ||
              (Moment(detail.startDate).format("YYYY-MM-DD") >= transactionDetail.startDate)) {
              toast.error(`For this date range ${transactionDetail.roleName} already exists, please select other date range`, {
                theme: 'colored'
              });

              loopBreaked = true;
            }
          }
        }
      })

      if (!loopBreaked) {
        if (transactionDetail.advocateId) {

          const updatedTransactionDetails = transactionDetailList.map(detail =>
            detail.advocateId === transactionDetail.advocateId ? { ...detail, ...transactionData } : detail
          );
          dispatch(
            formChangedAction({
              ...formChangedData,
              transactionDetailUpdate: true
            })
          );

          dispatch(transactionDataAction(undefined));
          dispatch(transactionDetailsAction(updatedTransactionDetails));
          toast.success('Transaction Updated Successfully', {
            theme: 'colored'
          });
          $('#TransactionDetailsTable').show();
          $('#TransactionDetailsListCard').show();

          setAmountPayable('');
          $('#AddAdvocateTransactionDetailsForm')[0].reset();
          clearStates();
          $('.payment-type-details').hide();

        } else {
          dispatch(transactionDetailsAction(transactionData));

          dispatch(
            formChangedAction({
              ...formChangedData,
              transactionDetailAdd: true
            })
          );
          toast.success('Transaction Added Successfully', {
            theme: 'colored'
          });

          $('#TransactionDetailsTable').show();
          $('#TransactionDetailsListCard').show();

          setAmountPayable('');
          $('#AddAdvocateTransactionDetailsForm')[0].reset();

          clearStates();
          $('.payment-type-details').hide();
          dispatch(transactionDataAction({}));
        }
      }
    }
  };

  const handleFieldChange = (e) => {
    if (e.target.value == "Cash") {
      dispatch(transactionDataAction({
        ...transactionDetail,
        paymentNo: "",
        [e.target.name]: e.target.value
      }));
      $('#txtPaymentNo').attr('disabled', true);
      setPaymentNoErr({});
    } else if (e.target.value == "Cheque" || e.target.value == "NEFT") {
      dispatch(transactionDataAction({
        ...transactionDetail,
        paymentNo: "",
        [e.target.name]: e.target.value
      }));
      $('#txtPaymentNo').attr('disabled', false);
    } else {
      dispatch(transactionDataAction({
        ...transactionDetail,
        [e.target.name]: e.target.value
      }));
    }
  };
  const handleQtyAmount = (e) => {
    const { name, value } = e.target;
    const updatedDetails = { ...transactionDetail, [name]: value };
    const qty = parseFloat(updatedDetails.qty) || 0;
    const billingAmount = parseFloat(updatedDetails.billingAmount) || 0;
    const gstPercent = parseFloat(updatedDetails.gstPercent) || 0;
    let totalAmount = 0;
    if (qty > 0 && billingAmount > 0 && gstPercent > 0) {
      var subTotalAmount = qty * billingAmount;
      var gstAmount = (subTotalAmount * gstPercent) / 100;
      totalAmount = subTotalAmount + gstAmount
      setGSTAmount(gstAmount)
    }
    else if (qty > 0 && billingAmount > 0) {
      totalAmount = qty * billingAmount
      setGSTAmount(0)
    }
    else{
      totalAmount = 0;
    }
    totalAmount > 0 ? setAmountPayable(totalAmount) : setAmountPayable(0);
    updatedDetails.totalAmount = totalAmount.toFixed(2);
    dispatch(transactionDataAction(updatedDetails));
  };

  const handleGstPercentChange = e => {
    dispatch(transactionDataAction({
      ...transactionDetail,
      [e.target.name]: e.target.value
    }));

    if (e.target.value > 0) {
      getTotalAmountWithGstPercent(transactionDetail.qty * transactionDetail.billingAmount, e.target.value);
    } else {
      getTotalAmountWithGstPercent(transactionDetail.qty * transactionDetail.billingAmount);
    }
  };

  const getTotalAmountWithGstPercent = (amount, gstPercent = 0) => {
    var gstAmount =
      gstPercent > 0 ? parseFloat((amount * gstPercent) / 100) : 0;
    setAmountPayable(parseFloat(amount) + gstAmount);
    setGSTAmount(gstAmount);
  };

  return (
    <>
      <Form
        noValidate
        validated={formHasError}
        className="details-form"
        onSubmit={e => {
          submitTransactionDetails(e);
        }}
        id="AddAdvocateTransactionDetailsForm"
      >
        <Row>
          <Col className="me-3 ms-3">
            <Row className="mb-3">
              <Form.Label>
                Role Name<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                name="roleId"
                id="setRoleName"
                disabled={transactionDetail.encryptedAdvocateId && true}
                onChange={handleFieldChange}
                value={transactionDetail.roleId}
                required
              >
                <option value="">Select Role</option>
                {roleList.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.key}
                  </option>
                ))}
              </Form.Select>
              {Object.keys(roleNameErr).map(key => {
                return (
                  <span className="error-message">{roleNameErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>
                Start Date<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                id="dtStartDate"
                name="startDate"
                value={transactionDetail.startDate ? Moment(transactionDetail.startDate).format("YYYY-MM-DD") : ""}
                onChange={handleFieldChange}
                required
              />
              {Object.keys(startDateErr).map(key => {
                return (
                  <span className="error-message">{startDateErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>
                Expiry Date<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                id="dtExpiryDate"
                name="expiryDate"
                value={transactionDetail.expiryDate ? Moment(transactionDetail.expiryDate).format("YYYY-MM-DD") : ""}
                onChange={handleFieldChange}
                required
              />
              {Object.keys(expiryDateErr).map(key => {
                return (
                  <span className="error-message">{expiryDateErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>
                Invoice Date<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                id="InvoiceDate"
                name="invoiceDate"
                value={transactionDetail.invoiceDate ? Moment(transactionDetail.invoiceDate).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD")}
                onChange={handleFieldChange}
                required
              />
              {Object.keys(invoiceDateError).map(key => {
                return (
                  <span className="error-message">{invoiceDateError[key]}</span>
                );
              })}
            </Row>
          </Col>

          <Col className="me-3 ms-3">
            <Row className="mb-3">
              <Form.Label>
                Invoice No.<span className="text-danger">*</span>
              </Form.Label>
              <EnlargableTextbox
                id="txtInvoiceNo"
                name="invoiceNo"
                placeholder="Enter invoice no"
                maxLength={15}
                onChange={handleFieldChange}
                value={transactionDetail.invoiceNo}
                required={true}
              />
              {Object.keys(invoiceNoErr).map(key => {
                return (
                  <span className="error-message">{invoiceNoErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>
                Payment Type<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                id="txtPaymentType"
                name="paymentType"
                value={transactionDetail.paymentType}
                onChange={handleFieldChange}
                required
              >
                <option value="">Select Payment Type</option>
                <option value="Cheque">Cheque</option>
                <option value="Cash">Cash</option>
                <option value="NEFT">NEFT</option>
              </Form.Select>
              {Object.keys(paymentTypeErr).map(key => {
                return (
                  <span className="error-message">{paymentTypeErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>Payment No.</Form.Label>
              <EnlargableTextbox
                id="txtPaymentNo"
                name="paymentNo"
                type="text"
                maxLength={30}
                value={transactionDetail.paymentNo}
                onChange={handleFieldChange}
                disabled
                placeholder="Enter payment no"
              />
              {Object.keys(paymentNoErr).map(key => {
                return (
                  <span className="error-message">{paymentNoErr[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>
                Qty<span className="text-danger">*</span>
              </Form.Label>
              <EnlargableTextbox
                placeholder="Enter qty"
                id="qtyValue"
                name="qty"
                maxLength={4}
                value={transactionDetail.qty}
                onChange={handleQtyAmount}
                required={true}
                onKeyPress={(e) => {
                  const key = String.fromCharCode(e.charCode);
                  const currentValue = e.target.value;
                  if (currentValue === "" && key === "0") {
                    e.preventDefault();
                  }

                  const regex = /^[0-9\.]+$/;
                  if (!regex.test(key) || (key === "." && currentValue.includes("."))) {
                    e.preventDefault();
                  }
                }}
              />
              {Object.keys(qtyError).map(key => {
                return (
                  <span className="error-message">{qtyError[key]}</span>
                );
              })}
            </Row>
          </Col>

          <Col className="me-3 ms-3">
            <Row className="mb-3">
              <Form.Label>
                HSN Code<span className="text-danger">*</span>
              </Form.Label>
              <EnlargableTextbox
                id="hsn_code"
                name="hsnCode"
                placeholder="Enter hsn code"
                maxLength={8}
                onChange={handleFieldChange}
                value={transactionDetail.hsnCode}
                required={true}
              />
              {Object.keys(hsnCodeError).map(key => {
                return (
                  <span className="error-message">{hsnCodeError[key]}</span>
                );
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>
                Billing Amount<span className="text-danger">*</span>
              </Form.Label>
              <EnlargableTextbox
                id="txtAmount"
                name="billingAmount"
                maxLength={13}
                min={0}
                onChange={handleQtyAmount}
                placeholder="Enter billing amount"
                required={true}
                value={transactionDetail.billingAmount}
                onKeyPress={e => {
                  const key = String.fromCharCode(e.charCode);
                  const currentValue = e.target.value;
                  if (key === '.' && currentValue.includes('.')) {
                    e.preventDefault();
                  }
                  const regex = /^[0-9\.]+$/;
                  if (!regex.test(key)) {
                    e.preventDefault();
                  }
                }}
              />
              {Object.keys(amountErr).map(key => {
                return <span className="error-message">{amountErr[key]}</span>;
              })}
            </Row>
            <Row className="mb-3">
              <Form.Label>GST Percentage</Form.Label>
              <EnlargableTextbox
                id="numGstPercent"
                name="gstPercent"
                maxLength={5}
                value={transactionDetail.gstPercent}
                min={0}
                onChange={handleGstPercentChange}
                placeholder="Enter gst percentage"
                onKeyPress={e => {
                  const key = String.fromCharCode(e.charCode);
                  const currentValue = e.target.value;

                  if (key === '.' && currentValue.includes('.')) {
                    e.preventDefault();
                    return;
                  }
                  const regex = /^[0-9\.]+$/;
                  if (!regex.test(key)) {
                    e.preventDefault();
                    return;
                  }
                  const potentialValue = parseFloat(currentValue + key);
                  if (!isNaN(potentialValue) && potentialValue > 99.99) {
                    e.preventDefault();
                  }
                }}
              />
            </Row>
            <Row className="mb-3">
              <Form.Label>Total Amount</Form.Label>
              <Form.Control
                type="number"
                id="numAmountPayable"
                name="totalAmount"
                disabled
                maxLength={13}
                value={amountPayable ? amountPayable : transactionDetail.totalAmount}
                onChange={handleFieldChange}
                onKeyPress={e => {
                  const key = String.fromCharCode(e.charCode);
                  const currentValue = e.target.value;
                  if (key === '.' && currentValue.includes('.')) {
                    e.preventDefault();
                  }
                  const regex = /^[0-9\.]+$/;
                  if (!regex.test(key)) {
                    e.preventDefault();
                  }
                }}
                placeholder="Total amount"
              />
            </Row>
            <Row className="mb-3">
              <Button variant="primary" type="submit">
                {transactionDetail.encryptedAdvocateId ? "Update" : "Add"}
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TransactionDetails;
