import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from '../../actions/index';
import { toast } from 'react-toastify';
import Treeview from 'components/common/Treeview';
import { securityMenuTreeMasterListAction, securityMenuTreeMasterListAPI } from 'actions/SecurityMenuTree/SecurityMenuTreeAction';
import { roleDetailAction } from 'actions/SecurityRoleMaster/securityRoleMasterAction';
import { selectedMenusAction } from 'actions/SecurityMenuRoleAsscociation/securityMenuRoleAssociation';

export const SecurityRoleMasterDetail = () => {
  const dispatch = useDispatch();
  const [selectedItems, setSelectedItems] = useState([]);
  const [formHasError, setFormError] = useState(false);
  const [menuTreeList, setMenuTreeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  const getSecurityMenuTreeMasterList = async () => {
    const requestParams = {
      encryptedRoleId: localStorage.getItem("EncryptedResponseRoleId") ? localStorage.getItem("EncryptedResponseRoleId") : ''
    }

    setIsLoading(true);
  await dispatch(securityMenuTreeMasterListAPI(requestParams,

      (res) => {
        setIsLoading(false)
        let treeMenuListData = [];
        treeMenuListData = res.data.data.length > 0 ? res.data.data : [];
        setMenuTreeList(treeMenuListData)
        dispatch(securityMenuTreeMasterListAction(treeMenuListData));

      },
      (err) => {
        setIsLoading(false)
        dispatch(securityMenuTreeMasterListAction(undefined));
        toast.error(err?.data.message, {
          theme: 'colored',
          autoClose: 10000
        });
      },
      headers
    ))
  }

  useEffect(() => {
    getSecurityMenuTreeMasterList();
  }, []);

  useEffect(() => {
    dispatch(selectedMenusAction(selectedItems));
  }, [selectedItems]);
  
  const resetRoleDetail = () => {
    dispatch(roleDetailAction({
      "roleName": "",
      "status": "Active"
    }))
    setSelectedItems([]);
  }

  const securityRoleMasterReducer = useSelector(
    state => state.rootReducer.securityRoleMasterReducer
  );
  const roleData = securityRoleMasterReducer.roleData;

  const securityRoleMasterErrorReducer = useSelector(
    state => state.rootReducer.securityRoleMasterErrorReducer
  );
   
  const roleDetailsError = securityRoleMasterErrorReducer.roleDetailsError;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  const handleSelectedItems = () => {
    if (securityRoleMasterReducer.roleData && roleData.treeIds && roleData.treeIds.length > 0) {
      setSelectedItems(roleData.treeIds)
    }
  }

  if (Object.keys(securityRoleMasterReducer.roleData).length == 0 && !securityRoleMasterReducer.roleData.encryptedRoleId) {
    resetRoleDetail();
  }
  else if(securityRoleMasterReducer.roleData.encryptedRoleId && (!selectedItems || selectedItems.length <= 0) && (!formChangedData.roleAdd && !formChangedData.roleDelete)){
    handleSelectedItems();
  }   

  const handleFieldChange = e => {
    dispatch(roleDetailAction({
      ...roleData,
      [e.target.name]: e.target.value
    }));

    if (roleData.encryptedRoleId) {
      dispatch(formChangedAction({
        ...formChangedData,
        roleUpdate: true
      }))
    } else {
      dispatch(formChangedAction({
        ...formChangedData,
        roleAdd: true
      }))
    }
  }

  return (
    <>
      {roleData && (
        <Form noValidate validated={formHasError} className="details-form" id='AddRoleDetailsForm'>
          <Row>
            <Col className="me-3 ms-3">
              <Row className="mb-3">
                <Form.Label>Role Name<span className="text-danger">*</span></Form.Label>
                <Form.Control id="txtRoleName" name="roleName" maxLength={100}
                  value={roleData.roleName}
                  onChange={handleFieldChange} />
                {Object.keys(roleDetailsError.roleNameErr).map((key) => {
                  return <span className="error-message">{roleDetailsError.roleNameErr[key]}</span>
                })}
              </Row>
              <Row className="mb-3">
                <Treeview
                  data={menuTreeList}
                  selection
                  defaultSelected={[]}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
              </Row>

            </Col>
            <Col className="me-3 ms-3">
              <Row className="mb-3">
                <Form.Label>Active Status</Form.Label>
                <Form.Select id="txtStatus" name="status"
                  value={roleData.status}
                  onChange={handleFieldChange}>
                  <option value="Active" selected>Active</option>
                  <option value="Suspended">Suspended</option>
                </Form.Select>
              </Row>
            </Col>
          </Row>
        </Form>
       )} 
    </>
  );
};

export default SecurityRoleMasterDetail;
