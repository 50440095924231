import React, { useState, useEffect } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { formChangedAction } from 'actions';
import FalconComponentCard from 'components/common/FalconComponentCard';
import { countryListAPI } from 'actions/CountryMaster/countryMasterAction';
import { courtMasterDataDetailAction } from 'actions/CourtMaster/courtMasterAction';
import { stateListAPI } from 'actions/StateMaster/stateMasterAction';
import { toast } from 'react-toastify';
import { getCourtTypeMasterListAPI } from 'actions/CourtTypeMaster/courtTypeMasterAction';

const CourtMasterDetail = () => {
  const dispatch = useDispatch();

  const resetCourtMasterData = () => {
    dispatch(
      courtMasterDataDetailAction({
        'courtName': '',
        'courtCode': '',
        'courtType': '',
        'countryCode': '',
        'stateCode': '',
        'courtLocation': '',
        'courtAddress': ''
      })
    );
  };

  useEffect(() => {
    getCountries();
    getCourtTypeList();
  }, []);

  const courtMasterReducer = useSelector(
    state => state.rootReducer.courtMasterReducer
  );
  var courtMasterData = courtMasterReducer.courtMasterDetail;

  if (
    !courtMasterReducer.courtMasterDetail ||
    courtMasterReducer.courtMasterDetail.length <= 0
  ) {
    resetCourtMasterData();
  }
  const courtMasterErrorReducer = useSelector(
    (state) => state.rootReducer.courtMasterErrorReducer
  );
  const courtMasterDetailsErr = courtMasterErrorReducer.courtMasterDetailsError;

  const formChangedReducer = useSelector(
    state => state.rootReducer.formChangedReducer
  );
  var formChangedData = formChangedReducer.formChanged;

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [courtTypeList, setCourtTypeList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
  const headers = {
    Authorization: `Bearer ${JSON.parse(token).value}`
  };

  useEffect(() => {
    if (courtMasterData && courtMasterData.stateCode && !$('#txtStateName').val()) {
      getStates(courtMasterData.countryCode);
    } else {
      setStateList([]);
    }
  }, [courtMasterData.countryCode]);

  const getCourtTypeList = (page, size = perPage) => {
    const data = {
      isDropdown: true
    };
    dispatch(
      getCourtTypeMasterListAPI(
        data,
        res => {
          let courtTypeData = [];
          if (res.data.data.length > 0)
            res.data.data.forEach(courtType => {
              courtTypeData.push({
                key: courtType.courtTypeName,
                value: courtType.courtTypeCode
              });
            });
          setCourtTypeList(courtTypeData);
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching court types list', {
            theme: 'colored',
            autoClose: 10000
          });
          setCourtTypeList([]);
        },
        headers
      )
    );
  };

  const getCountries = (page, size = perPage) => {
    const data = {
      pageNumber: page,
      pageSize: size,
      SearchText: '',
      isDropDown: true
    };
    dispatch(
      countryListAPI(
        data,
        res => {
          let countryData = [];
          if (res.data.data.length > 0)
            res.data.data.forEach(country => {
              countryData.push({
                key: country.countryName,
                value: country.countryCode
              });
            });
          setCountryList(countryData);
        },
        err => {
          toast.error(err?.data?.message || 'Error fetching countries', {
            theme: 'colored',
            autoClose: 10000
          });
          setCountryList([]);
        },
        headers
      )
    );
  };

  const getStates = async selectCountryCode => {
    const data = {
      countryCode: selectCountryCode,
      isDropDown: true,
      isList: false
    };
    dispatch(
      stateListAPI(
        data,
        res => {
          let stateData = [];
          if (res && res.length > 0) {
            res.forEach(state => {
              stateData.push({
                key: state.stateName,
                value: state.stateCode
              });
            });
            setStateList(stateData);
          } else {
            setStateList([]);
          }
        },
        err => {
          setStateList([]);
        }
      )
    );
  };

  const handleFieldChange = e => {
    dispatch(
      courtMasterDataDetailAction({
        ...courtMasterData,
        [e.target.name]: e.target.value
      })
    );
    if (e.target.name == 'countryCode') {
      dispatch(
        courtMasterDataDetailAction({
          ...courtMasterData,
          countryCode: e.target.value,
          stateCode: null
        })
      );
      setStateList([]);
      e.target.value && getStates(e.target.value);
    } else {
      dispatch(
        courtMasterDataDetailAction({
          ...courtMasterData,
          [e.target.name]: e.target.value
        })
      );
    }
    if (courtMasterData.encryptedCourtCode) {
      dispatch(
        formChangedAction({
          ...formChangedData,
          courtMasterUpdate: true
        })
      );
    } else {
      dispatch(
        formChangedAction({
          ...formChangedData,
          courtMasterAdd: true
        })
      );
    }
  };
  
  return (
    <>
      {courtMasterData && (
        <FalconComponentCard className="no-pb mb-1">
          <FalconComponentCard.Body language="jsx">
            <Form className="details-form" id="addCourtMasterDetailsForm">
              <Row>
                <Col md="4">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Court Code
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        id="txtCourtCode"
                        name="courtCode"
                        maxLength={5}
                        placeholder="Court Code"
                        value={courtMasterData.courtCode}
                        disabled
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Court Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        id="txtCourtName"
                        maxLength={50}
                        name="courtName"
                        placeholder="Court Name"
                        value={courtMasterData.courtName}
                        onChange={e => handleFieldChange(e)}
                      />
                      {Object.keys(courtMasterDetailsErr.courtNameErr).map(
                        key => {
                          return (
                            <span className="error-message">
                              {courtMasterDetailsErr.courtNameErr[key]}
                            </span>
                          );
                        }
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Court Type<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Select
                        id="txtCourtType"
                        name="courtType"
                        value={courtMasterData.courtType}
                        onChange={handleFieldChange}
                      >
                        <option value="">Select</option>
                        {courtTypeList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </Form.Select>
                      {Object.keys(courtMasterDetailsErr.courtTypeErr).map(
                        key => {
                          return (
                            <span className="error-message">
                              {courtMasterDetailsErr.courtTypeErr[key]}
                            </span>
                          );
                        }
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Court Location
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        id="txtCourtLocation"
                        name="courtLocation"
                        maxLength={50}
                        placeholder="Court Location"
                        value={courtMasterData.courtLocation}
                        onChange={handleFieldChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Court Address
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        id="txtCourtAddress"
                        name="courtAddress"
                        maxLength={200}
                        placeholder="Court Address"
                        value={courtMasterData.courtAddress}
                        onChange={handleFieldChange}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      Country Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Select
                        id="txtCountryName"
                        name="countryCode"
                        value={courtMasterData.countryCode}
                        onChange={handleFieldChange}
                      >
                        <option value="">Select</option>
                        {countryList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </Form.Select>
                      {Object.keys(courtMasterDetailsErr.countryNameErr).map(
                        key => {
                          return (
                            <span className="error-message">
                              {courtMasterDetailsErr.countryNameErr[key]}
                            </span>
                          );
                        }
                      )}
                    </Col>
                  </Form.Group>
                </Col>
                <Col md="4">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextPassword"
                  >
                    <Form.Label column sm="4">
                      State Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Select
                        id="txtStateName"
                        name="stateCode"
                        value={courtMasterData.stateCode}
                        onChange={handleFieldChange}
                      >
                        <option value="">Select</option>
                        {stateList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.key}
                          </option>
                        ))}
                      </Form.Select>

                      {Object.keys(courtMasterDetailsErr.stateNameErr).map(
                        key => {
                          return (
                            <span className="error-message">
                              {courtMasterDetailsErr.stateNameErr[key]}
                            </span>
                          );
                        }
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </FalconComponentCard.Body>
        </FalconComponentCard>
      )}
    </>
  );
};

export default CourtMasterDetail;