import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row, Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import Moment from "moment";
import { toast } from 'react-toastify';
import TablePagination from 'components/common/TablePagination';
import { clientListAPI } from 'actions/ClientRegistration/ClientRegistrationAction';
import { getClientCaseMasterListAPI } from 'actions/CaseNextDate/caseNextDateAction';
import { caseScheduleAction, caseScheduleDetailsErrorAction, getCaseScheduleListAPI } from 'actions/CaseSchedule/caseScheduleAction';
import { courtMasterListAPI } from 'actions/CourtMaster/courtMasterAction';
import Client from 'components/Clients/Client';
import { useNavigate } from 'react-router-dom';

export const CaseScheduleDetail = () => {

    
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [clientCaseMasterList, setClientCaseMasterList] = useState([]);
    const [caseScheduleList, setCaseScheduleList] = useState([]);
    var groupIndexs = 1;


    const [pageNumber, setPageNumber] = useState(1);
    const [courtMasterList, setCourtMasterList] = useState([]);
    const [pageSize, setPageSize] = useState(5);
    const [inventoryCount, setInventoryCount] = useState(0);

    const [formHasError, setFormError] = useState(false);
    const selectedClientCode = localStorage.getItem('ClientCode');
    const selectedCaseNo = localStorage.getItem('CaseNo');
    let pageCount = Math.ceil(inventoryCount / pageSize);
    let token = localStorage.getItem('Token') || sessionStorage.getItem('Token');
    const headers = {
        Authorization: `Bearer ${JSON.parse(token).value}`
    };
    const [mergedCaseScheduleList, setMergedCaseScheduleList] = useState([]);
    let isSearch = false;

    // const resetCaseScheduleData = () => {
    //     dispatch(caseScheduleAction({
    //       "clientCode": "",
    //       "clientName": "",
    //       "courtCode": "",
    //       "ClientCaseNo": "",
    //       "fromDate": Moment().format('YYYY-MM-DD'),
    //       "toDate": Moment().format('YYYY-MM-DD'),

    //     }));
    //   }
    const caseScheduleReducer = useSelector((state) => state.rootReducer.caseScheduleReducer)
    var caseScheduleData = caseScheduleReducer.caseScheduleData;


    const caseScheduleErrorReducer = useSelector((state) => state.rootReducer.caseScheduleErrorReducer)
    const caseScheduleError = caseScheduleErrorReducer.caseScheduleError;

    const [formData, setFormData] = useState({
        clientCode: localStorage.getItem('ClientCode') ? localStorage.getItem('ClientCode') : "",
        fromDate: null,
        endDate: null,
        productCategoryCode: "",
        searchText: ""
    });
    const isPreviousDisabled = pageNumber === 1;
    const isNextDisabled = pageNumber === pageCount;
    useEffect(() => {
        // localStorage.removeItem('ClientCode');
        // localStorage.removeItem('ClientCaseNo');
        // localStorage.removeItem('CourtNo');

    }, [])


    useEffect(() => {
        if (clientList.length <= 0) {
            getClientList();
        }
        // if (localStorage.getItem("ClientCode")) {
        //     dispatch(
        //         caseScheduleAction({
        //             ...caseScheduleData,
        //             clientCode: localStorage.getItem("ClientCode"),

        //         })
        //     );

        //     fetchCaseScheduleList(localStorage.getItem("ClientCode"))

        // }
        if (caseScheduleList && caseScheduleList.length > 0) {
            const mergedList = mergeRowsByNextDateLineName(caseScheduleList);
            setMergedCaseScheduleList(mergedList);
        }


    }, [caseScheduleList])

    const getClientList = async () => {
        const data = {
            pageNumber: 1,
            pageSize: 1,
            SearchText: '',
            EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
            isDropDown: true
        };

        dispatch(
            clientListAPI(
                data,
                res => {
                    let clientData = [];

                    if (res.data.data.clientList.length > 0)
                        res.data.data.clientList.forEach(client => {
                            clientData.push({
                                key: client.clientName,
                                value: client.clientCode
                            });
                        });
                    setClientList(clientData);
                },
                err => {
                    setClientList([]);
                    console.log(err?.data.message);
                },
                headers
            )
        );
    };

    const getClientCaseMasterList = async clientCode => {
        
        const data = {
            EncryptedAdvocateCode: localStorage.getItem('EncryptedAdvocateCode'),
            ClientCode: clientCode ? clientCode : selectedClientCode
        };
        await dispatch(
            getClientCaseMasterListAPI(
                data,
                res => {
                    let caseMasterData = [];
                    if (res.data && res.data.data.caseMasterList.length > 0) {
                        res.data.data.caseMasterList.forEach(data => {
                            caseMasterData.push({
                                key: `${data.caseNo} --- ${data.caseTitle}`,
                                value: { clientCaseNo: data.caseNo, courtCode: data.courtCode }, 
                            });
                         
                        });
                        
                        setClientCaseMasterList(caseMasterData);
                        // localStorage.setItem('CourtCode', res.data.data.courtCode)
                        // dispatch(
                        //     caseScheduleAction({
                        //         ...caseScheduleData,
                        //         courtCode: res.data.data.courtCode,
                        //     })
                        // );
                    } else {
                        setClientCaseMasterList([]);
                    }
                },
                err => {
                    setClientCaseMasterList([]);
                },
                headers
            )
        );
    };

    const getCourtMasterList = async () => {
        const data = {
            stateCode: "",
            IsCaseSchedule: true
        }
        await dispatch(courtMasterListAPI(
            data,
            res => {
                let courtData = [];
                if (res.data && res.data.data.courtList.length > 0) {
                    res.data.data.courtList.forEach(data => {
                        courtData.push({
                            key: data.courtName,
                            value: data.courtCode
                        });
                    });
                    setCourtMasterList(courtData);
                } else {
                    setCourtMasterList([]);
                }
            },
            (err) => {
                setCourtMasterList([]);
            },
            headers
        ));
    };

    const fetchCaseScheduleList = async (newPageNumber = "1", newPageSize = "15") => {


        if (caseScheduleValidation()) {
            let requestData = {
                EncryptedAdvocateCode: localStorage.getItem("EncryptedAdvocateCode"),
                ClientCode: localStorage.getItem("ClientCode") ? localStorage.getItem("ClientCode") : caseScheduleData.clientCode ? caseScheduleData.clientCode : "",
                FromDate: caseScheduleData.fromDate ? Moment(caseScheduleData.fromDate).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
                ToDate: caseScheduleData.toDate ? Moment(caseScheduleData.toDate).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD"),
                ClientCaseMo: localStorage.getItem("ClientCaseNo") ? localStorage.getItem("ClientCaseNo") : caseScheduleData.clientCaseNo ? caseScheduleData.lientCaseNo : "",
                CourtCode: localStorage.getItem('CourtCode') ? localStorage.getItem('CourtCode') : caseScheduleData.courtCode ? caseScheduleData.courtCode : "",
                PageNumber: newPageSize ? 1 : newPageNumber ? newPageNumber : pageNumber,
                PageSize: newPageSize ? newPageSize : pageSize
            }
            await dispatch(
                getCaseScheduleListAPI(
                    requestData,
                    res => {
                        setCaseScheduleList(res.data.caseScheduleList)
                        dispatch(caseScheduleAction(res.data.caseScheduleList))

                    },
                    err => {
                        dispatch(caseScheduleAction(undefined))
                        console.log(err?.data.message);
                    },
                    headers
                )
            );
        }
    }

    const handleFieldChange = (e) => {
        if (e.target.name === 'clientCode') {
            
            localStorage.setItem('ClientCode', e.target.value);
            // localStorage.removeItem('CaseNo');
            // setClientCaseMasterList([]);
            dispatch(
                caseScheduleAction({
                    ...caseScheduleData,
                    clientCode: e.target.value,
                    clientCaseNo: null
                })
            );
            getClientCaseMasterList(e.target.value);
        }else if(e.target.name === 'clientCaseNo') {
    
            const selectedValue = JSON.parse(e.target.value);
            const { clientCaseNo, courtCode } = selectedValue;
            localStorage.setItem('ClientCaseNo', clientCaseNo);
            // Log the separated values

            // localStorage.setItem('ClientCaseNo', e.target.value);
            // localStorage.removeItem('courtCode');
            // setClientCaseMasterList([]);
            dispatch(
                caseScheduleAction({
                    ...caseScheduleData,
                    clientCaseNo: clientCaseNo,
                })
            );
            // getCourtMasterList()
      
        }
            else {
            dispatch(
                caseScheduleAction({
                    ...caseScheduleData,
                    [e.target.name]: e.target.value,
                })
            );

        }
    }
    const caseScheduleValidation = () => {
        const clientNameErr = {};
        const datesErr = {};


        let isValid = true;
        if (!caseScheduleData.clientCode) {
            toast.error("Select client", {
                theme: 'colored'
            });
            isValid = false;
            setFormError(true);
        }

        if (caseScheduleData.fromDate > caseScheduleData.toDate) {
            toast.error("From Date cannot be greater than To Date", {
                theme: 'colored'
            });
            isValid = false;
            setFormError(true);
        }
        if (!isValid) {
            var errorObject = {
                clientNameErr,
                datesErr

            };
            dispatch(caseScheduleDetailsErrorAction(errorObject));
        }

        return isValid;
    };

    const mergeRowsByNextDateLineName = (list) => {
        const groupedByNextDateLineAndCategory = {};

        list.forEach((item) => {
            const key = `${item.nextDate}_${item.courtName}`;

            if (!groupedByNextDateLineAndCategory[key]) {
                groupedByNextDateLineAndCategory[key] = [item];
            } else {
                groupedByNextDateLineAndCategory[key].push(item);
            }
        });

        const mergedList = Object.values(groupedByNextDateLineAndCategory);

        return mergedList;
    };

    const onPageClick = async ({ selected }) => {
        setPageNumber(selected + 1);
        isSearch = false;
        fetchCaseScheduleList(caseScheduleData.clientCode, false, selected + 1)
    }

    const handlePageSize = async (e) => {
        setPageSize(e.target.value);
        setPageNumber(1);
        isSearch = false;
        fetchCaseScheduleList(caseScheduleData.clientCode, false, 1, e.target.value)
    }

    const handlePreviousClick = async () => {
        setPageNumber(pageNumber - 1);
        isSearch = false;
        fetchCaseScheduleList(caseScheduleData.clientCode, false, pageNumber - 1)
    }

    const handleNextClick = async () => {
        setPageNumber(pageNumber + 1);
        isSearch = false;
        fetchCaseScheduleList(caseScheduleData.clientCode, false, pageNumber + 1)
    }

    return (
        <>
            {isLoading ? (
                <Spinner
                    className="position-absolute start-50 loader-color"
                    animation="border"
                />
            ) : null}

            <Form className="details-form" id="InventoryDetailDashboard">
                <Row className='mb-1 ms-2 justify-content-left'>
                    <Form.Label column className='col-auto'>
                        Client Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Col className='col-auto'>

                        <Form.Select id="txtClientCode" name="clientCode" onChange={handleFieldChange} value={caseScheduleData.clientCode}>
                            <option value=''>Select</option>
                            {clientList.map((option, index) => (
                                <option key={index} value={option.value}>{option.key}</option>
                            ))}
                        </Form.Select>

                    </Col>
                    <Form.Label column className='col-auto'>Case No</Form.Label>
                    <Col className='col-2'>
                        <Form.Select id="txtClientCaseNo" name="clientCaseNo"  value={JSON.stringify(caseScheduleData.clientCaseNo)} 
                        // value={caseScheduleData.clientCaseNo} 
                        onChange={(e)=>handleFieldChange(e)}
                        >
                            <option value=''>Select</option>
                            {clientCaseMasterList.map((option, index) => (
                                <option key={index}
                                value={JSON.stringify(option.value)} 
                                 >{option.key}</option>
                            ))}
                        </Form.Select>
                    </Col>
                    <Form.Label column className='col-auto'>Court Name</Form.Label>
                    <Col className='col-3'>
                        <Form.Select id="txtCourtCode" name="courtCode" value={localStorage.getItem("CourtCode") ? localStorage.getItem("CourtCode") : caseScheduleData.CourtCode} onChange={handleFieldChange}
                        >
                            <option value=''>Select</option>
                            {courtMasterList.map((option, index) => (
                                <option key={index} value={option.value}>{option.key}</option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>

                <Row className='justify-content-left ms-2'>
                    <Form.Label column className='col-auto'>
                        From Date
                    </Form.Label>
                    <Col className='col-auto'>
                        <Form.Control type='date' id="dtFromDate" name="fromDate" onChange={handleFieldChange}
                            value={caseScheduleData.fromDate ? Moment(caseScheduleData.fromDate).format('YYYY-MM-DD') :  Moment().format('YYYY-MM-DD')}
                            max={Moment().format("YYYY-MM-DD")} />
                    </Col>
                    <Form.Label column className='col-auto'>
                        To Date
                    </Form.Label>
                    <Col className='col-auto'>
                        <Form.Control type='date' id="dtToDate" name="toDate" onChange={handleFieldChange}
                            value={caseScheduleData.toDate ? Moment(caseScheduleData.toDate).format('YYYY-MM-DD') :  Moment().format('YYYY-MM-DD')}
                            max={Moment().format("YYYY-MM-DD")} />
                    </Col>

                    <Col className='col-auto'>
                        <Button onClick={fetchCaseScheduleList}>Show Case Report</Button>
                    </Col>
                    <Col className='col-auto'>
                        <Button>Generate PDF Report</Button>
                    </Col>
                    <Col className='col-auto'>
                        <Button>Next Details</Button>
                    </Col>
                </Row>

                {
                    mergedCaseScheduleList && mergedCaseScheduleList.length > 0 ?
                        <Row className="no-padding">
                            <Table striped bordered responsive className="table-md overflow-hidden">
                                <thead className='custom-bg-200'>
                                    <tr>
                                        <th>S. No</th>
                                        <th>Next Date</th>
                                        <th>Court Name</th>
                                        <th>Case Title</th>
                                        <th>Case No</th>
                                        <th>Case Type</th>
                                        <th>Judge Name</th>
                                        <th>Court No</th>
                                        <th> Purpose</th>
                                        <th>Previous Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mergedCaseScheduleList.map((group, groupIndex) => (
                                        <React.Fragment key={groupIndex}>
                                            {group.map((item, index) => (
                                                <tr key={`${groupIndex}_${index}`}>
                                                    {index === 0 && (
                                                        <>
                                                            <td rowSpan={group.length}>{groupIndex + 1}</td>
                                                            <td rowSpan={group.length}>{Moment(item.nextDate).format('DD-MMMM-YYYY')} </td>  
                                                            <td rowSpan={group.length}>{item.courtName}</td>
                                                        </>
                                                    )}
                                                    <td className='pl-5'>{item.caseTitle}</td>
                                                    <td>{item.clientCaseNo}</td>
                                                    <td>{item.caseTypeName}</td>
                                                    <td>{item.judgeName ? item.judgeName : "-"}</td>
                                                    <td>{item.courtNo ? item.courtNo : "-"}</td>
                                                    <td>{item.purpose ? item.purpose : "-"}</td>
                                                    <td>{Moment(item.previousDate).format('DD-MMMM-YYYY')}</td>
                                                    <td><span onClick={() => Navigate()}>
                                                        <svg
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="edit"
                                                            class="svg-inline--fa fa-edit fa-w-15 fa-2x"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 576 512"
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                                                            ></path>
                                                        </svg>
                                                    </span></td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </Row>
                        :
                        <Row className='justify-content-center mt-2'>
                            <Col className='col-auto'>
                                <h4 id="no-inventory-message"></h4>
                            </Col>
                        </Row>
                }
            </Form>
            <Row style={{ position: 'absolute', bottom: '30px', align: 'centre' }}>
                <TablePagination
                    pageCount={pageCount}
                    handlePageClick={onPageClick}
                    pageSize={pageSize}
                    handlePageSizeChange={handlePageSize}
                    isDisablePrevious={isPreviousDisabled}
                    isDisableNext={isNextDisabled}
                    previousClick={handlePreviousClick}
                    nextClick={handleNextClick}
                    pageIndex={pageNumber - 1}
                />
            </Row>
        </>
    )
}